.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.NotesArea {
    line-height: 22px !important;
}

.NotesArea ol, .NotesArea ul {
    padding-inline-start: 16px;
}

.NotesArea p {
    margin-top: 8px;
    margin-bottom: 8px;
}

.NotesArea a {
    color: #007bff !important;
}

/*!
 * Start Bootstrap - Landing Page v4.0.0-beta.2 (https://startbootstrap.com/template-overviews/landing-page)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-landing-page/blob/master/LICENSE)
 */
 
 header.masthead {
    position: relative;
    background-color: #343a40;
    background: url(/images/runsheetbg.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 8rem;
    padding-bottom: 8rem; }
    header.masthead .overlay {
      position: absolute;
      background-color: #212529;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0.5; }
    header.masthead h1 {
      font-size: 2rem; }
    @media (min-width: 768px) {
      header.masthead {
        padding-top: 12rem;
        padding-bottom: 12rem; }
        header.masthead h1 {
          font-size: 3rem; } }
  
  .showcase .showcase-text {
    padding: 3rem; }
  
  .showcase .showcase-img {
    min-height: 30rem;
    background-size: cover; }
  
  @media (min-width: 768px) {
    .showcase .showcase-text {
      padding: 7rem; } }
  
  .features-icons {
    padding-top: 7rem;
    padding-bottom: 7rem; }
    .features-icons .features-icons-item {
      max-width: 20rem; }
      .features-icons .features-icons-item .features-icons-icon {
        height: 7rem; }
        .features-icons .features-icons-item .features-icons-icon i {
          font-size: 4.5rem; }
      .features-icons .features-icons-item:hover .features-icons-icon i {
        font-size: 5rem; }
  
  .testimonials {
    padding-top: 7rem;
    padding-bottom: 7rem; }
    .testimonials .testimonial-item {
      max-width: 18rem; }
      .testimonials .testimonial-item img {
        max-width: 12rem;
        box-shadow: 0px 5px 5px 0px #adb5bd; }
  
  .call-to-action {
    position: relative;
    background-color: #343a40;
    background: url(/images/runsheetbg.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 7rem;
    padding-bottom: 7rem; }
    .call-to-action .overlay {
      position: absolute;
      background-color: #212529;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0.3; }
  
  footer.footer {
    padding-top: 4rem;
    padding-bottom: 4rem; }


a:-webkit-any-link {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}
