/*!
 * Bootstrap v4.0.0-beta.3 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue:#007bff;
  --indigo:#6610f2;
  --purple:#6f42c1;
  --pink:#e83e8c;
  --red:#dc3545;
  --orange:#fd7e14;
  --yellow:#ffc107;
  --green:#28a745;
  --teal:#20c997;
  --cyan:#17a2b8;
  --white:#fff;
  --gray:#868e96;
  --gray-dark:#343a40;
  --primary:#007bff;
  --secondary:#868e96;
  --success:#28a745;
  --info:#17a2b8;
  --warning:#ffc107;
  --danger:#dc3545;
  --light:#f8f9fa;
  --dark:#343a40;
  --breakpoint-xs:0;
  --breakpoint-sm:576px;
  --breakpoint-md:768px;
  --breakpoint-lg:992px;
  --breakpoint-xl:1200px;
  --font-family-sans-serif:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  --font-family-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace; }

#splash {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

#splash hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

#splash h1, #splash h2, #splash h3, #splash h4, #splash h5, #splash h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

#splash p {
  margin-top: 0;
  margin-bottom: 1rem; }

#splash abbr[data-original-title], #splash abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

#splash address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

#splash dl, #splash ol, #splash ul {
  margin-top: 0;
  margin-bottom: 1rem; }

#splash ol ol, #splash ol ul, #splash ul ol, #splash ul ul {
  margin-bottom: 0; }

#splash dt {
  font-weight: 700; }

#splash dd {
  margin-bottom: .5rem;
  margin-left: 0; }

#splash blockquote {
  margin: 0 0 1rem; }

#splash dfn {
  font-style: italic; }

#splash b, #splash strong {
  font-weight: bolder; }

#splash small {
  font-size: 80%; }

#splash sub, #splash sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

#splash sub {
  bottom: -.25em; }

#splash sup {
  top: -.5em; }

#splash a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

#splash a:hover {
  color: #0056b3;
  text-decoration: underline; }

#splash a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

#splash a:not([href]):not([tabindex]):focus, #splash a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none; }

#splash a:not([href]):not([tabindex]):focus {
  outline: 0; }

#splash code, #splash kbd, #splash pre, #splash samp {
  font-family: monospace,monospace;
  font-size: 1em; }

#splash pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

#splash figure {
  margin: 0 0 1rem; }

#splash img {
  vertical-align: middle;
  border-style: none; }

#splash svg:not(:root) {
  overflow: hidden; }

#splash [role=button], #splash a, #splash area, #splash button, #splash input:not([type=range]), #splash label, #splash select, #splash summary, #splash textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

#splash table {
  border-collapse: collapse; }

#splash caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

#splash th {
  text-align: inherit; }

#splash label {
  display: inline-block;
  margin-bottom: .5rem; }

#splash button {
  border-radius: 0; }

#splash button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

#splash button, #splash input, #splash optgroup, #splash select, #splash textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

#splash button, #splash input {
  overflow: visible; }

#splash button, #splash select {
  text-transform: none; }

#splash [type=reset], #splash [type=submit], #splash button, #splash html [type=button] {
  -webkit-appearance: button; }

#splash [type=button]::-moz-focus-inner, #splash [type=reset]::-moz-focus-inner, #splash [type=submit]::-moz-focus-inner, #splash button::-moz-focus-inner {
  padding: 0;
  border-style: none; }

#splash input[type=checkbox], #splash input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

#splash input[type=date], #splash input[type=datetime-local], #splash input[type=month], #splash input[type=time] {
  -webkit-appearance: listbox; }

#splash textarea {
  overflow: auto;
  resize: vertical; }

#splash fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

#splash legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

#splash progress {
  vertical-align: baseline; }

#splash [type=number]::-webkit-inner-spin-button, #splash [type=number]::-webkit-outer-spin-button {
  height: auto; }

#splash [type=search] {
  outline-offset: -2px;
  -webkit-appearance: none; }

#splash [type=search]::-webkit-search-cancel-button, #splash [type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

#splash ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

#splash output {
  display: inline-block; }

#splash summary {
  display: list-item;
  cursor: pointer; }

#splash template {
  display: none; }

#splash [hidden] {
  display: none !important; }

#splash .h1, #splash .h2, #splash .h3, #splash .h4, #splash .h5, #splash .h6, #splash h1, #splash h2, #splash h3, #splash h4, #splash h5, #splash h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

#splash .h1, #splash h1 {
  font-size: 2.5rem; }

#splash .h2, #splash h2 {
  font-size: 2rem; }

#splash .h3, #splash h3 {
  font-size: 1.75rem; }

#splash .h4, #splash h4 {
  font-size: 1.5rem; }

#splash .h5, #splash h5 {
  font-size: 1.25rem; }

#splash .h6, #splash h6 {
  font-size: 1rem; }

#splash .lead {
  font-size: 1.25rem;
  font-weight: 300; }

#splash .display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

#splash .display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

#splash .display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

#splash .display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

#splash hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

#splash .small, #splash small {
  font-size: 80%;
  font-weight: 400; }

#splash .mark, #splash mark {
  padding: .2em;
  background-color: #fcf8e3; }

#splash .list-unstyled {
  padding-left: 0;
  list-style: none; }

#splash .list-inline {
  padding-left: 0;
  list-style: none; }

#splash .list-inline-item {
  display: inline-block; }

#splash .list-inline-item:not(:last-child) {
  margin-right: .5rem; }

#splash .initialism {
  font-size: 90%;
  text-transform: uppercase; }

#splash .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

#splash .blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96; }

#splash .blockquote-footer::before {
  content: "\2014 \00A0"; }

#splash .img-fluid {
  max-width: 100%;
  height: auto; }

#splash .img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto; }

#splash .figure {
  display: inline-block; }

#splash .figure-img {
  margin-bottom: .5rem;
  line-height: 1; }

#splash .figure-caption {
  font-size: 90%;
  color: #868e96; }

#splash code, #splash kbd, #splash pre, #splash samp {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace; }

#splash code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }

#splash a > code {
  color: inherit; }

#splash kbd {
  padding: .2rem .4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: .2rem; }

#splash kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700; }

#splash pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }

#splash pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal; }

#splash .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

#splash .container {
  width: 100%;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  #splash .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  #splash .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  #splash .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  #splash .container {
    max-width: 1140px; } }

#splash .container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

#splash .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

#splash .no-gutters {
  margin-right: 0;
  margin-left: 0; }

#splash .no-gutters > .col, #splash .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0; }

#splash .col, #splash .col-1, #splash .col-10, #splash .col-11, #splash .col-12, #splash .col-2, #splash .col-3, #splash .col-4, #splash .col-5, #splash .col-6, #splash .col-7, #splash .col-8, #splash .col-9, #splash .col-auto, #splash .col-lg, #splash .col-lg-1, #splash .col-lg-10, #splash .col-lg-11, #splash .col-lg-12, #splash .col-lg-2, #splash .col-lg-3, #splash .col-lg-4, #splash .col-lg-5, #splash .col-lg-6, #splash .col-lg-7, #splash .col-lg-8, #splash .col-lg-9, #splash .col-lg-auto, #splash .col-md, #splash .col-md-1, #splash .col-md-10, #splash .col-md-11, #splash .col-md-12, #splash .col-md-2, #splash .col-md-3, #splash .col-md-4, #splash .col-md-5, #splash .col-md-6, #splash .col-md-7, #splash .col-md-8, #splash .col-md-9, #splash .col-md-auto, #splash .col-sm, #splash .col-sm-1, #splash .col-sm-10, #splash .col-sm-11, #splash .col-sm-12, #splash .col-sm-2, #splash .col-sm-3, #splash .col-sm-4, #splash .col-sm-5, #splash .col-sm-6, #splash .col-sm-7, #splash .col-sm-8, #splash .col-sm-9, #splash .col-sm-auto, #splash .col-xl, #splash .col-xl-1, #splash .col-xl-10, #splash .col-xl-11, #splash .col-xl-12, #splash .col-xl-2, #splash .col-xl-3, #splash .col-xl-4, #splash .col-xl-5, #splash .col-xl-6, #splash .col-xl-7, #splash .col-xl-8, #splash .col-xl-9, #splash .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

#splash .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

#splash .col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

#splash .col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

#splash .col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

#splash .col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

#splash .col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

#splash .col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

#splash .col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

#splash .col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

#splash .col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

#splash .col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

#splash .col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

#splash .col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

#splash .col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

#splash .order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1; }

#splash .order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1; }

#splash .order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2; }

#splash .order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3; }

#splash .order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4; }

#splash .order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5; }

#splash .order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6; }

#splash .order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7; }

#splash .order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8; }

#splash .order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9; }

#splash .order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10; }

#splash .order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11; }

#splash .order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12; }

#splash .offset-1 {
  margin-left: 8.333333%; }

#splash .offset-2 {
  margin-left: 16.666667%; }

#splash .offset-3 {
  margin-left: 25%; }

#splash .offset-4 {
  margin-left: 33.333333%; }

#splash .offset-5 {
  margin-left: 41.666667%; }

#splash .offset-6 {
  margin-left: 50%; }

#splash .offset-7 {
  margin-left: 58.333333%; }

#splash .offset-8 {
  margin-left: 66.666667%; }

#splash .offset-9 {
  margin-left: 75%; }

#splash .offset-10 {
  margin-left: 83.333333%; }

#splash .offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  #splash .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  #splash .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  #splash .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  #splash .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  #splash .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  #splash .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  #splash .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  #splash .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  #splash .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  #splash .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  #splash .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  #splash .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  #splash .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  #splash .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  #splash .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  #splash .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  #splash .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  #splash .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  #splash .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  #splash .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  #splash .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  #splash .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  #splash .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  #splash .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  #splash .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  #splash .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  #splash .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  #splash .offset-sm-0 {
    margin-left: 0; }
  #splash .offset-sm-1 {
    margin-left: 8.333333%; }
  #splash .offset-sm-2 {
    margin-left: 16.666667%; }
  #splash .offset-sm-3 {
    margin-left: 25%; }
  #splash .offset-sm-4 {
    margin-left: 33.333333%; }
  #splash .offset-sm-5 {
    margin-left: 41.666667%; }
  #splash .offset-sm-6 {
    margin-left: 50%; }
  #splash .offset-sm-7 {
    margin-left: 58.333333%; }
  #splash .offset-sm-8 {
    margin-left: 66.666667%; }
  #splash .offset-sm-9 {
    margin-left: 75%; }
  #splash .offset-sm-10 {
    margin-left: 83.333333%; }
  #splash .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  #splash .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  #splash .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  #splash .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  #splash .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  #splash .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  #splash .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  #splash .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  #splash .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  #splash .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  #splash .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  #splash .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  #splash .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  #splash .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  #splash .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  #splash .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  #splash .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  #splash .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  #splash .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  #splash .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  #splash .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  #splash .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  #splash .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  #splash .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  #splash .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  #splash .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  #splash .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  #splash .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  #splash .offset-md-0 {
    margin-left: 0; }
  #splash .offset-md-1 {
    margin-left: 8.333333%; }
  #splash .offset-md-2 {
    margin-left: 16.666667%; }
  #splash .offset-md-3 {
    margin-left: 25%; }
  #splash .offset-md-4 {
    margin-left: 33.333333%; }
  #splash .offset-md-5 {
    margin-left: 41.666667%; }
  #splash .offset-md-6 {
    margin-left: 50%; }
  #splash .offset-md-7 {
    margin-left: 58.333333%; }
  #splash .offset-md-8 {
    margin-left: 66.666667%; }
  #splash .offset-md-9 {
    margin-left: 75%; }
  #splash .offset-md-10 {
    margin-left: 83.333333%; }
  #splash .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  #splash .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  #splash .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  #splash .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  #splash .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  #splash .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  #splash .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  #splash .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  #splash .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  #splash .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  #splash .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  #splash .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  #splash .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  #splash .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  #splash .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  #splash .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  #splash .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  #splash .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  #splash .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  #splash .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  #splash .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  #splash .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  #splash .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  #splash .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  #splash .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  #splash .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  #splash .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  #splash .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  #splash .offset-lg-0 {
    margin-left: 0; }
  #splash .offset-lg-1 {
    margin-left: 8.333333%; }
  #splash .offset-lg-2 {
    margin-left: 16.666667%; }
  #splash .offset-lg-3 {
    margin-left: 25%; }
  #splash .offset-lg-4 {
    margin-left: 33.333333%; }
  #splash .offset-lg-5 {
    margin-left: 41.666667%; }
  #splash .offset-lg-6 {
    margin-left: 50%; }
  #splash .offset-lg-7 {
    margin-left: 58.333333%; }
  #splash .offset-lg-8 {
    margin-left: 66.666667%; }
  #splash .offset-lg-9 {
    margin-left: 75%; }
  #splash .offset-lg-10 {
    margin-left: 83.333333%; }
  #splash .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  #splash .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  #splash .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  #splash .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  #splash .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  #splash .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  #splash .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  #splash .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  #splash .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  #splash .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  #splash .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  #splash .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  #splash .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  #splash .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  #splash .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  #splash .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  #splash .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  #splash .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  #splash .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  #splash .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  #splash .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  #splash .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  #splash .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  #splash .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  #splash .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  #splash .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  #splash .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  #splash .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  #splash .offset-xl-0 {
    margin-left: 0; }
  #splash .offset-xl-1 {
    margin-left: 8.333333%; }
  #splash .offset-xl-2 {
    margin-left: 16.666667%; }
  #splash .offset-xl-3 {
    margin-left: 25%; }
  #splash .offset-xl-4 {
    margin-left: 33.333333%; }
  #splash .offset-xl-5 {
    margin-left: 41.666667%; }
  #splash .offset-xl-6 {
    margin-left: 50%; }
  #splash .offset-xl-7 {
    margin-left: 58.333333%; }
  #splash .offset-xl-8 {
    margin-left: 66.666667%; }
  #splash .offset-xl-9 {
    margin-left: 75%; }
  #splash .offset-xl-10 {
    margin-left: 83.333333%; }
  #splash .offset-xl-11 {
    margin-left: 91.666667%; } }

#splash .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }

#splash .table td, #splash .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6; }

#splash .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6; }

#splash .table tbody + tbody {
  border-top: 2px solid #dee2e6; }

#splash .table .table {
  background-color: #fff; }

#splash .table-sm td, #splash .table-sm th {
  padding: .3rem; }

#splash .table-bordered {
  border: 1px solid #dee2e6; }

#splash .table-bordered td, #splash .table-bordered th {
  border: 1px solid #dee2e6; }

#splash .table-bordered thead td, #splash .table-bordered thead th {
  border-bottom-width: 2px; }

#splash .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

#splash .table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

#splash .table-primary, #splash .table-primary > td, #splash .table-primary > th {
  background-color: #b8daff; }

#splash .table-hover .table-primary:hover {
  background-color: #9fcdff; }

#splash .table-hover .table-primary:hover > td, #splash .table-hover .table-primary:hover > th {
  background-color: #9fcdff; }

#splash .table-secondary, #splash .table-secondary > td, #splash .table-secondary > th {
  background-color: #dddfe2; }

#splash .table-hover .table-secondary:hover {
  background-color: #cfd2d6; }

#splash .table-hover .table-secondary:hover > td, #splash .table-hover .table-secondary:hover > th {
  background-color: #cfd2d6; }

#splash .table-success, #splash .table-success > td, #splash .table-success > th {
  background-color: #c3e6cb; }

#splash .table-hover .table-success:hover {
  background-color: #b1dfbb; }

#splash .table-hover .table-success:hover > td, #splash .table-hover .table-success:hover > th {
  background-color: #b1dfbb; }

#splash .table-info, #splash .table-info > td, #splash .table-info > th {
  background-color: #bee5eb; }

#splash .table-hover .table-info:hover {
  background-color: #abdde5; }

#splash .table-hover .table-info:hover > td, #splash .table-hover .table-info:hover > th {
  background-color: #abdde5; }

#splash .table-warning, #splash .table-warning > td, #splash .table-warning > th {
  background-color: #ffeeba; }

#splash .table-hover .table-warning:hover {
  background-color: #ffe8a1; }

#splash .table-hover .table-warning:hover > td, #splash .table-hover .table-warning:hover > th {
  background-color: #ffe8a1; }

#splash .table-danger, #splash .table-danger > td, #splash .table-danger > th {
  background-color: #f5c6cb; }

#splash .table-hover .table-danger:hover {
  background-color: #f1b0b7; }

#splash .table-hover .table-danger:hover > td, #splash .table-hover .table-danger:hover > th {
  background-color: #f1b0b7; }

#splash .table-light, #splash .table-light > td, #splash .table-light > th {
  background-color: #fdfdfe; }

#splash .table-hover .table-light:hover {
  background-color: #ececf6; }

#splash .table-hover .table-light:hover > td, #splash .table-hover .table-light:hover > th {
  background-color: #ececf6; }

#splash .table-dark, #splash .table-dark > td, #splash .table-dark > th {
  background-color: #c6c8ca; }

#splash .table-hover .table-dark:hover {
  background-color: #b9bbbe; }

#splash .table-hover .table-dark:hover > td, #splash .table-hover .table-dark:hover > th {
  background-color: #b9bbbe; }

#splash .table-active, #splash .table-active > td, #splash .table-active > th {
  background-color: rgba(0, 0, 0, 0.075); }

#splash .table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }

#splash .table-hover .table-active:hover > td, #splash .table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075); }

#splash .table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

#splash .table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

#splash .table-dark {
  color: #fff;
  background-color: #212529; }

#splash .table-dark td, #splash .table-dark th, #splash .table-dark thead th {
  border-color: #32383e; }

#splash .table-dark.table-bordered {
  border: 0; }

#splash .table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05); }

#splash .table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.99px) {
  #splash .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  #splash .table-responsive-sm > .table-bordered {
    border: 0; } }

@media (max-width: 767.99px) {
  #splash .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  #splash .table-responsive-md > .table-bordered {
    border: 0; } }

@media (max-width: 991.99px) {
  #splash .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  #splash .table-responsive-lg > .table-bordered {
    border: 0; } }

@media (max-width: 1199.99px) {
  #splash .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  #splash .table-responsive-xl > .table-bordered {
    border: 0; } }

#splash .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

#splash .table-responsive > .table-bordered {
  border: 0; }

#splash .form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

#splash .form-control::-ms-expand {
  background-color: transparent;
  border: 0; }

#splash .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

#splash .form-control::-webkit-input-placeholder {
  color: #868e96;
  opacity: 1; }

#splash .form-control::-moz-placeholder {
  color: #868e96;
  opacity: 1; }

#splash .form-control:-ms-input-placeholder {
  color: #868e96;
  opacity: 1; }

#splash .form-control::-ms-input-placeholder {
  color: #868e96;
  opacity: 1; }

#splash .form-control::placeholder {
  color: #868e96;
  opacity: 1; }

#splash .form-control:disabled, #splash .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1; }

#splash select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

#splash select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

#splash .form-control-file, #splash .form-control-range {
  display: block;
  width: 100%; }

#splash .col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

#splash .col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

#splash .col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5; }

#splash .form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: .375rem;
  padding-bottom: .375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }

#splash .form-control-plaintext.form-control-lg, #splash .form-control-plaintext.form-control-sm, #splash .input-group-lg > .form-control-plaintext.form-control, #splash .input-group-lg > .input-group-append > .form-control-plaintext.btn, #splash .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text, #splash .input-group-lg > .input-group-prepend > .form-control-plaintext.btn, #splash .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text, #splash .input-group-sm > .form-control-plaintext.form-control, #splash .input-group-sm > .input-group-append > .form-control-plaintext.btn, #splash .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text, #splash .input-group-sm > .input-group-prepend > .form-control-plaintext.btn, #splash .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text {
  padding-right: 0;
  padding-left: 0; }

#splash .form-control-sm, #splash .input-group-sm > .form-control, #splash .input-group-sm > .input-group-append > .btn, #splash .input-group-sm > .input-group-append > .input-group-text, #splash .input-group-sm > .input-group-prepend > .btn, #splash .input-group-sm > .input-group-prepend > .input-group-text {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem; }

#splash .input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]), #splash .input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]), #splash .input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]), #splash .input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), #splash .input-group-sm > select.form-control:not([size]):not([multiple]), #splash select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

#splash .form-control-lg, #splash .input-group-lg > .form-control, #splash .input-group-lg > .input-group-append > .btn, #splash .input-group-lg > .input-group-append > .input-group-text, #splash .input-group-lg > .input-group-prepend > .btn, #splash .input-group-lg > .input-group-prepend > .input-group-text {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem; }

#splash .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]), #splash .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]), #splash .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), #splash .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), #splash .input-group-lg > select.form-control:not([size]):not([multiple]), #splash select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

#splash .form-group {
  margin-bottom: 1rem; }

#splash .form-text {
  display: block;
  margin-top: .25rem; }

#splash .form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }

#splash .form-row > .col, #splash .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px; }

#splash .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

#splash .form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem; }

#splash .form-check-input:disabled ~ .form-check-label {
  color: #868e96; }

#splash .form-check-label {
  margin-bottom: 0; }

#splash .form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem; }

#splash .form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0; }

#splash .valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #28a745; }

#splash .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

#splash .custom-select.is-valid, #splash .form-control.is-valid, #splash .was-validated .custom-select:valid, #splash .was-validated .form-control:valid {
  border-color: #28a745; }

#splash .custom-select.is-valid:focus, #splash .form-control.is-valid:focus, #splash .was-validated .custom-select:valid:focus, #splash .was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

#splash .custom-select.is-valid ~ .valid-feedback, #splash .custom-select.is-valid ~ .valid-tooltip, #splash .form-control.is-valid ~ .valid-feedback, #splash .form-control.is-valid ~ .valid-tooltip, #splash .was-validated .custom-select:valid ~ .valid-feedback, #splash .was-validated .custom-select:valid ~ .valid-tooltip, #splash .was-validated .form-control:valid ~ .valid-feedback, #splash .was-validated .form-control:valid ~ .valid-tooltip {
  display: block; }

#splash .form-check-input.is-valid ~ .form-check-label, #splash .was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745; }

#splash .custom-control-input.is-valid ~ .custom-control-label, #splash .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745; }

#splash .custom-control-input.is-valid ~ .custom-control-label::before, #splash .was-validated .custom-control-input:valid ~ .custom-control-label::before {
  background-color: #71dd8a; }

#splash .custom-control-input.is-valid ~ .valid-feedback, #splash .custom-control-input.is-valid ~ .valid-tooltip, #splash .was-validated .custom-control-input:valid ~ .valid-feedback, #splash .was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block; }

#splash .custom-control-input.is-valid:checked ~ .custom-control-label::before, #splash .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

#splash .custom-control-input.is-valid:focus ~ .custom-control-label::before, #splash .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

#splash .custom-file-input.is-valid ~ .custom-file-label, #splash .was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745; }

#splash .custom-file-input.is-valid ~ .custom-file-label::before, #splash .was-validated .custom-file-input:valid ~ .custom-file-label::before {
  border-color: inherit; }

#splash .custom-file-input.is-valid ~ .valid-feedback, #splash .custom-file-input.is-valid ~ .valid-tooltip, #splash .was-validated .custom-file-input:valid ~ .valid-feedback, #splash .was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block; }

#splash .custom-file-input.is-valid:focus ~ .custom-file-label, #splash .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

#splash .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545; }

#splash .invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

#splash .custom-select.is-invalid, #splash .form-control.is-invalid, #splash .was-validated .custom-select:invalid, #splash .was-validated .form-control:invalid {
  border-color: #dc3545; }

#splash .custom-select.is-invalid:focus, #splash .form-control.is-invalid:focus, #splash .was-validated .custom-select:invalid:focus, #splash .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

#splash .custom-select.is-invalid ~ .invalid-feedback, #splash .custom-select.is-invalid ~ .invalid-tooltip, #splash .form-control.is-invalid ~ .invalid-feedback, #splash .form-control.is-invalid ~ .invalid-tooltip, #splash .was-validated .custom-select:invalid ~ .invalid-feedback, #splash .was-validated .custom-select:invalid ~ .invalid-tooltip, #splash .was-validated .form-control:invalid ~ .invalid-feedback, #splash .was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block; }

#splash .form-check-input.is-invalid ~ .form-check-label, #splash .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545; }

#splash .custom-control-input.is-invalid ~ .custom-control-label, #splash .was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545; }

#splash .custom-control-input.is-invalid ~ .custom-control-label::before, #splash .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  background-color: #efa2a9; }

#splash .custom-control-input.is-invalid ~ .invalid-feedback, #splash .custom-control-input.is-invalid ~ .invalid-tooltip, #splash .was-validated .custom-control-input:invalid ~ .invalid-feedback, #splash .was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block; }

#splash .custom-control-input.is-invalid:checked ~ .custom-control-label::before, #splash .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

#splash .custom-control-input.is-invalid:focus ~ .custom-control-label::before, #splash .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

#splash .custom-file-input.is-invalid ~ .custom-file-label, #splash .was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545; }

#splash .custom-file-input.is-invalid ~ .custom-file-label::before, #splash .was-validated .custom-file-input:invalid ~ .custom-file-label::before {
  border-color: inherit; }

#splash .custom-file-input.is-invalid ~ .invalid-feedback, #splash .custom-file-input.is-invalid ~ .invalid-tooltip, #splash .was-validated .custom-file-input:invalid ~ .invalid-feedback, #splash .was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block; }

#splash .custom-file-input.is-invalid:focus ~ .custom-file-label, #splash .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

#splash .form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

#splash .form-inline .form-check {
  width: 100%; }

@media (min-width: 576px) {
  #splash .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0; }
  #splash .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0; }
  #splash .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  #splash .form-inline .form-control-plaintext {
    display: inline-block; }
  #splash .form-inline .input-group {
    width: auto; }
  #splash .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0; }
  #splash .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0; }
  #splash .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  #splash .form-inline .custom-control-label {
    margin-bottom: 0; } }

#splash .btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

#splash .btn:focus, #splash .btn:hover {
  text-decoration: none; }

#splash .btn.focus, #splash .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

#splash .btn.disabled, #splash .btn:disabled {
  opacity: .65; }

#splash .btn:not([disabled]):not(.disabled) {
  cursor: pointer; }

#splash .btn:not([disabled]):not(.disabled).active, #splash .btn:not([disabled]):not(.disabled):active {
  background-image: none; }

#splash a.btn.disabled, #splash fieldset[disabled] a.btn {
  pointer-events: none; }

#splash .btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

#splash .btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc; }

#splash .btn-primary.focus, #splash .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

#splash .btn-primary.disabled, #splash .btn-primary:disabled {
  background-color: #007bff;
  border-color: #007bff; }

#splash .btn-primary:not([disabled]):not(.disabled).active, #splash .btn-primary:not([disabled]):not(.disabled):active, #splash .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf; }

#splash .btn-primary:not([disabled]):not(.disabled).active:focus, #splash .btn-primary:not([disabled]):not(.disabled):active:focus, #splash .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

#splash .btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96; }

#splash .btn-secondary:hover {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d; }

#splash .btn-secondary.focus, #splash .btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5); }

#splash .btn-secondary.disabled, #splash .btn-secondary:disabled {
  background-color: #868e96;
  border-color: #868e96; }

#splash .btn-secondary:not([disabled]):not(.disabled).active, #splash .btn-secondary:not([disabled]):not(.disabled):active, #splash .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #666e76; }

#splash .btn-secondary:not([disabled]):not(.disabled).active:focus, #splash .btn-secondary:not([disabled]):not(.disabled):active:focus, #splash .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5); }

#splash .btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

#splash .btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34; }

#splash .btn-success.focus, #splash .btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

#splash .btn-success.disabled, #splash .btn-success:disabled {
  background-color: #28a745;
  border-color: #28a745; }

#splash .btn-success:not([disabled]):not(.disabled).active, #splash .btn-success:not([disabled]):not(.disabled):active, #splash .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430; }

#splash .btn-success:not([disabled]):not(.disabled).active:focus, #splash .btn-success:not([disabled]):not(.disabled):active:focus, #splash .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

#splash .btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

#splash .btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b; }

#splash .btn-info.focus, #splash .btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

#splash .btn-info.disabled, #splash .btn-info:disabled {
  background-color: #17a2b8;
  border-color: #17a2b8; }

#splash .btn-info:not([disabled]):not(.disabled).active, #splash .btn-info:not([disabled]):not(.disabled):active, #splash .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f; }

#splash .btn-info:not([disabled]):not(.disabled).active:focus, #splash .btn-info:not([disabled]):not(.disabled):active:focus, #splash .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

#splash .btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

#splash .btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00; }

#splash .btn-warning.focus, #splash .btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

#splash .btn-warning.disabled, #splash .btn-warning:disabled {
  background-color: #ffc107;
  border-color: #ffc107; }

#splash .btn-warning:not([disabled]):not(.disabled).active, #splash .btn-warning:not([disabled]):not(.disabled):active, #splash .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500; }

#splash .btn-warning:not([disabled]):not(.disabled).active:focus, #splash .btn-warning:not([disabled]):not(.disabled):active:focus, #splash .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

#splash .btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

#splash .btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130; }

#splash .btn-danger.focus, #splash .btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

#splash .btn-danger.disabled, #splash .btn-danger:disabled {
  background-color: #dc3545;
  border-color: #dc3545; }

#splash .btn-danger:not([disabled]):not(.disabled).active, #splash .btn-danger:not([disabled]):not(.disabled):active, #splash .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d; }

#splash .btn-danger:not([disabled]):not(.disabled).active:focus, #splash .btn-danger:not([disabled]):not(.disabled):active:focus, #splash .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

#splash .btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

#splash .btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5; }

#splash .btn-light.focus, #splash .btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

#splash .btn-light.disabled, #splash .btn-light:disabled {
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

#splash .btn-light:not([disabled]):not(.disabled).active, #splash .btn-light:not([disabled]):not(.disabled):active, #splash .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df; }

#splash .btn-light:not([disabled]):not(.disabled).active:focus, #splash .btn-light:not([disabled]):not(.disabled):active:focus, #splash .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

#splash .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

#splash .btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124; }

#splash .btn-dark.focus, #splash .btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

#splash .btn-dark.disabled, #splash .btn-dark:disabled {
  background-color: #343a40;
  border-color: #343a40; }

#splash .btn-dark:not([disabled]):not(.disabled).active, #splash .btn-dark:not([disabled]):not(.disabled):active, #splash .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d; }

#splash .btn-dark:not([disabled]):not(.disabled).active:focus, #splash .btn-dark:not([disabled]):not(.disabled):active:focus, #splash .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

#splash .btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }

#splash .btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

#splash .btn-outline-primary.focus, #splash .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

#splash .btn-outline-primary.disabled, #splash .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent; }

#splash .btn-outline-primary:not([disabled]):not(.disabled).active, #splash .btn-outline-primary:not([disabled]):not(.disabled):active, #splash .show > .btn-outline-primary.dropdown-toggle {
  color: #212529;
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

#splash .btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96; }

#splash .btn-outline-secondary:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96; }

#splash .btn-outline-secondary.focus, #splash .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5); }

#splash .btn-outline-secondary.disabled, #splash .btn-outline-secondary:disabled {
  color: #868e96;
  background-color: transparent; }

#splash .btn-outline-secondary:not([disabled]):not(.disabled).active, #splash .btn-outline-secondary:not([disabled]):not(.disabled):active, #splash .show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #868e96;
  border-color: #868e96;
  box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5); }

#splash .btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }

#splash .btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

#splash .btn-outline-success.focus, #splash .btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

#splash .btn-outline-success.disabled, #splash .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent; }

#splash .btn-outline-success:not([disabled]):not(.disabled).active, #splash .btn-outline-success:not([disabled]):not(.disabled):active, #splash .show > .btn-outline-success.dropdown-toggle {
  color: #212529;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

#splash .btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }

#splash .btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

#splash .btn-outline-info.focus, #splash .btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

#splash .btn-outline-info.disabled, #splash .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent; }

#splash .btn-outline-info:not([disabled]):not(.disabled).active, #splash .btn-outline-info:not([disabled]):not(.disabled):active, #splash .show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

#splash .btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }

#splash .btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

#splash .btn-outline-warning.focus, #splash .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

#splash .btn-outline-warning.disabled, #splash .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent; }

#splash .btn-outline-warning:not([disabled]):not(.disabled).active, #splash .btn-outline-warning:not([disabled]):not(.disabled):active, #splash .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

#splash .btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }

#splash .btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

#splash .btn-outline-danger.focus, #splash .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

#splash .btn-outline-danger.disabled, #splash .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent; }

#splash .btn-outline-danger:not([disabled]):not(.disabled).active, #splash .btn-outline-danger:not([disabled]):not(.disabled):active, #splash .show > .btn-outline-danger.dropdown-toggle {
  color: #212529;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

#splash .btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }

#splash .btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

#splash .btn-outline-light.focus, #splash .btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

#splash .btn-outline-light.disabled, #splash .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent; }

#splash .btn-outline-light:not([disabled]):not(.disabled).active, #splash .btn-outline-light:not([disabled]):not(.disabled):active, #splash .show > .btn-outline-light.dropdown-toggle {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

#splash .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }

#splash .btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

#splash .btn-outline-dark.focus, #splash .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

#splash .btn-outline-dark.disabled, #splash .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent; }

#splash .btn-outline-dark:not([disabled]):not(.disabled).active, #splash .btn-outline-dark:not([disabled]):not(.disabled):active, #splash .show > .btn-outline-dark.dropdown-toggle {
  color: #212529;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

#splash .btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }

#splash .btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent; }

#splash .btn-link.focus, #splash .btn-link:focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none; }

#splash .btn-link.disabled, #splash .btn-link:disabled {
  color: #868e96; }

#splash .btn-group-lg > .btn, #splash .btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem; }

#splash .btn-group-sm > .btn, #splash .btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem; }

#splash .btn-block {
  display: block;
  width: 100%; }

#splash .btn-block + .btn-block {
  margin-top: .5rem; }

#splash input[type=button].btn-block, #splash input[type=reset].btn-block, #splash input[type=submit].btn-block {
  width: 100%; }

#splash .fade {
  opacity: 0;
  transition: opacity .15s linear; }

#splash .fade.show {
  opacity: 1; }

#splash .collapse {
  display: none; }

#splash .collapse.show {
  display: block; }

#splash tr.collapse.show {
  display: table-row; }

#splash tbody.collapse.show {
  display: table-row-group; }

#splash .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease; }

#splash .dropdown, #splash .dropup {
  position: relative; }

#splash .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent; }

#splash .dropdown-toggle:empty::after {
  margin-left: 0; }

#splash .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem; }

#splash .dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: .125rem; }

#splash .dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent; }

#splash .dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

#splash .dropright .dropdown-menu {
  margin-top: 0;
  margin-left: .125rem; }

#splash .dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-bottom: .3em solid transparent;
  border-left: .3em solid; }

#splash .dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

#splash .dropright .dropdown-toggle::after {
  vertical-align: 0; }

#splash .dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: .125rem; }

#splash .dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: ""; }

#splash .dropleft .dropdown-toggle::after {
  display: none; }

#splash .dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent; }

#splash .dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

#splash .dropleft .dropdown-toggle::before {
  vertical-align: 0; }

#splash .dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

#splash .dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }

#splash .dropdown-item:focus, #splash .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa; }

#splash .dropdown-item.active, #splash .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff; }

#splash .dropdown-item.disabled, #splash .dropdown-item:disabled {
  color: #868e96;
  background-color: transparent; }

#splash .dropdown-menu.show {
  display: block; }

#splash .dropdown-header {
  display: block;
  padding: .5rem 1.5rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #868e96;
  white-space: nowrap; }

#splash .btn-group, #splash .btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }

#splash .btn-group-vertical > .btn, #splash .btn-group > .btn {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto; }

#splash .btn-group-vertical > .btn:hover, #splash .btn-group > .btn:hover {
  z-index: 1; }

#splash .btn-group-vertical > .btn.active, #splash .btn-group-vertical > .btn:active, #splash .btn-group-vertical > .btn:focus, #splash .btn-group > .btn.active, #splash .btn-group > .btn:active, #splash .btn-group > .btn:focus {
  z-index: 1; }

#splash .btn-group .btn + .btn, #splash .btn-group .btn + .btn-group, #splash .btn-group .btn-group + .btn, #splash .btn-group .btn-group + .btn-group, #splash .btn-group-vertical .btn + .btn, #splash .btn-group-vertical .btn + .btn-group, #splash .btn-group-vertical .btn-group + .btn, #splash .btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px; }

#splash .btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

#splash .btn-toolbar .input-group {
  width: auto; }

#splash .btn-group > .btn:first-child {
  margin-left: 0; }

#splash .btn-group > .btn-group:not(:last-child) > .btn, #splash .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

#splash .btn-group > .btn-group:not(:first-child) > .btn, #splash .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

#splash .dropdown-toggle-split {
  padding-right: .5625rem;
  padding-left: .5625rem; }

#splash .dropdown-toggle-split::after {
  margin-left: 0; }

#splash .btn-group-sm > .btn + .dropdown-toggle-split, #splash .btn-sm + .dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem; }

#splash .btn-group-lg > .btn + .dropdown-toggle-split, #splash .btn-lg + .dropdown-toggle-split {
  padding-right: .75rem;
  padding-left: .75rem; }

#splash .btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

#splash .btn-group-vertical .btn, #splash .btn-group-vertical .btn-group {
  width: 100%; }

#splash .btn-group-vertical > .btn + .btn, #splash .btn-group-vertical > .btn + .btn-group, #splash .btn-group-vertical > .btn-group + .btn, #splash .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0; }

#splash .btn-group-vertical > .btn-group:not(:last-child) > .btn, #splash .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

#splash .btn-group-vertical > .btn-group:not(:first-child) > .btn, #splash .btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

#splash .btn-group-toggle > .btn, #splash .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }

#splash .btn-group-toggle > .btn input[type=checkbox], #splash .btn-group-toggle > .btn input[type=radio], #splash .btn-group-toggle > .btn-group > .btn input[type=checkbox], #splash .btn-group-toggle > .btn-group > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

#splash .input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%; }

#splash .input-group .custom-file, #splash .input-group .custom-select, #splash .input-group .form-control {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0; }

#splash .input-group .custom-file:focus, #splash .input-group .custom-select:focus, #splash .input-group .form-control:focus {
  z-index: 3; }

#splash .input-group .custom-file + .form-control, #splash .input-group .custom-select + .form-control, #splash .input-group .form-control + .form-control {
  margin-left: -1px; }

#splash .input-group .custom-select:not(:last-child), #splash .input-group .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

#splash .input-group .custom-select:not(:first-child), #splash .input-group .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

#splash .input-group .custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

#splash .input-group .custom-file:not(:last-child) .custom-file-control, #splash .input-group .custom-file:not(:last-child) .custom-file-control::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

#splash .input-group .custom-file:not(:first-child) .custom-file-control, #splash .input-group .custom-file:not(:first-child) .custom-file-control::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

#splash .input-group-append, #splash .input-group-prepend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

#splash .input-group-append .btn, #splash .input-group-prepend .btn {
  position: relative;
  z-index: 2; }

#splash .input-group-append .btn + .btn, #splash .input-group-append .btn + .input-group-text, #splash .input-group-append .input-group-text + .btn, #splash .input-group-append .input-group-text + .input-group-text, #splash .input-group-prepend .btn + .btn, #splash .input-group-prepend .btn + .input-group-text, #splash .input-group-prepend .input-group-text + .btn, #splash .input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px; }

#splash .input-group-prepend {
  margin-right: -1px; }

#splash .input-group-append {
  margin-left: -1px; }

#splash .input-group-text {
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

#splash .input-group-text input[type=checkbox], #splash .input-group-text input[type=radio] {
  margin-top: 0; }

#splash .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), #splash .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), #splash .input-group > .input-group-append:not(:last-child) > .btn, #splash .input-group > .input-group-append:not(:last-child) > .input-group-text, #splash .input-group > .input-group-prepend > .btn, #splash .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

#splash .input-group > .input-group-append > .btn, #splash .input-group > .input-group-append > .input-group-text, #splash .input-group > .input-group-prepend:first-child > .btn:not(:first-child), #splash .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), #splash .input-group > .input-group-prepend:not(:first-child) > .btn, #splash .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

#splash .custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

#splash .custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

#splash .custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

#splash .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff; }

#splash .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

#splash .custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff; }

#splash .custom-control-input:disabled ~ .custom-control-label {
  color: #868e96; }

#splash .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef; }

#splash .custom-control-label {
  margin-bottom: 0; }

#splash .custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6; }

#splash .custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

#splash .custom-checkbox .custom-control-label::before {
  border-radius: .25rem; }

#splash .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

#splash .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

#splash .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

#splash .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

#splash .custom-radio .custom-control-label::before {
  border-radius: 50%; }

#splash .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

#splash .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

#splash .custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

#splash .custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

#splash .custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

#splash .custom-select[multiple], #splash .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: .75rem;
  background-image: none; }

#splash .custom-select:disabled {
  color: #868e96;
  background-color: #e9ecef; }

#splash .custom-select::-ms-expand {
  opacity: 0; }

#splash .custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: 75%; }

#splash .custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: 125%; }

#splash .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

#splash .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }

#splash .custom-file-input:focus ~ .custom-file-control {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

#splash .custom-file-input:focus ~ .custom-file-control::before {
  border-color: #80bdff; }

#splash .custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse"; }

#splash .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

#splash .custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.25rem + 2px) - 1px * 2);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 .25rem .25rem 0; }

#splash .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

#splash .nav-link {
  display: block;
  padding: .5rem 1rem; }

#splash .nav-link:focus, #splash .nav-link:hover {
  text-decoration: none; }

#splash .nav-link.disabled {
  color: #868e96; }

#splash .nav-tabs {
  border-bottom: 1px solid #dee2e6; }

#splash .nav-tabs .nav-item {
  margin-bottom: -1px; }

#splash .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

#splash .nav-tabs .nav-link:focus, #splash .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6; }

#splash .nav-tabs .nav-link.disabled {
  color: #868e96;
  background-color: transparent;
  border-color: transparent; }

#splash .nav-tabs .nav-item.show .nav-link, #splash .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff; }

#splash .nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

#splash .nav-pills .nav-link {
  border-radius: .25rem; }

#splash .nav-pills .nav-link.active, #splash .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

#splash .nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center; }

#splash .nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center; }

#splash .tab-content > .tab-pane {
  display: none; }

#splash .tab-content > .active {
  display: block; }

#splash .navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: .5rem 1rem; }

#splash .navbar > .container, #splash .navbar > .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

#splash .navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }

#splash .navbar-brand:focus, #splash .navbar-brand:hover {
  text-decoration: none; }

#splash .navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

#splash .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0; }

#splash .navbar-nav .dropdown-menu {
  position: static;
  float: none; }

#splash .navbar-text {
  display: inline-block;
  padding-top: .5rem;
  padding-bottom: .5rem; }

#splash .navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

#splash .navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem; }

#splash .navbar-toggler:focus, #splash .navbar-toggler:hover {
  text-decoration: none; }

#splash .navbar-toggler:not([disabled]):not(.disabled) {
  cursor: pointer; }

#splash .navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.99px) {
  #splash .navbar-expand-sm > .container, #splash .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  #splash .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  #splash .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  #splash .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute; }
  #splash .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  #splash .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  #splash .navbar-expand-sm > .container, #splash .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  #splash .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  #splash .navbar-expand-sm .navbar-toggler {
    display: none; }
  #splash .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

@media (max-width: 767.99px) {
  #splash .navbar-expand-md > .container, #splash .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  #splash .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  #splash .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  #splash .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute; }
  #splash .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  #splash .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  #splash .navbar-expand-md > .container, #splash .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  #splash .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  #splash .navbar-expand-md .navbar-toggler {
    display: none; }
  #splash .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

@media (max-width: 991.99px) {
  #splash .navbar-expand-lg > .container, #splash .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  #splash .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  #splash .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  #splash .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute; }
  #splash .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  #splash .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  #splash .navbar-expand-lg > .container, #splash .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  #splash .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  #splash .navbar-expand-lg .navbar-toggler {
    display: none; }
  #splash .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

@media (max-width: 1199.99px) {
  #splash .navbar-expand-xl > .container, #splash .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  #splash .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  #splash .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  #splash .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute; }
  #splash .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  #splash .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  #splash .navbar-expand-xl > .container, #splash .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  #splash .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  #splash .navbar-expand-xl .navbar-toggler {
    display: none; }
  #splash .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

#splash .navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

#splash .navbar-expand > .container, #splash .navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0; }

#splash .navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row; }

#splash .navbar-expand .navbar-nav .dropdown-menu {
  position: absolute; }

#splash .navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto; }

#splash .navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem; }

#splash .navbar-expand > .container, #splash .navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

#splash .navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto; }

#splash .navbar-expand .navbar-toggler {
  display: none; }

#splash .navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%; }

#splash .navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }

#splash .navbar-light .navbar-brand:focus, #splash .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9); }

#splash .navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }

#splash .navbar-light .navbar-nav .nav-link:focus, #splash .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7); }

#splash .navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3); }

#splash .navbar-light .navbar-nav .active > .nav-link, #splash .navbar-light .navbar-nav .nav-link.active, #splash .navbar-light .navbar-nav .nav-link.show, #splash .navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9); }

#splash .navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

#splash .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

#splash .navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }

#splash .navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9); }

#splash .navbar-light .navbar-text a:focus, #splash .navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9); }

#splash .navbar-dark .navbar-brand {
  color: #fff; }

#splash .navbar-dark .navbar-brand:focus, #splash .navbar-dark .navbar-brand:hover {
  color: #fff; }

#splash .navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }

#splash .navbar-dark .navbar-nav .nav-link:focus, #splash .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75); }

#splash .navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25); }

#splash .navbar-dark .navbar-nav .active > .nav-link, #splash .navbar-dark .navbar-nav .nav-link.active, #splash .navbar-dark .navbar-nav .nav-link.show, #splash .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff; }

#splash .navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

#splash .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

#splash .navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }

#splash .navbar-dark .navbar-text a {
  color: #fff; }

#splash .navbar-dark .navbar-text a:focus, #splash .navbar-dark .navbar-text a:hover {
  color: #fff; }

#splash .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem; }

#splash .card > hr {
  margin-right: 0;
  margin-left: 0; }

#splash .card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

#splash .card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

#splash .card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem; }

#splash .card-title {
  margin-bottom: .75rem; }

#splash .card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0; }

#splash .card-text:last-child {
  margin-bottom: 0; }

#splash .card-link:hover {
  text-decoration: none; }

#splash .card-link + .card-link {
  margin-left: 1.25rem; }

#splash .card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

#splash .card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0; }

#splash .card-header + .list-group .list-group-item:first-child {
  border-top: 0; }

#splash .card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

#splash .card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px); }

#splash .card-header-tabs {
  margin-right: -.625rem;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  border-bottom: 0; }

#splash .card-header-pills {
  margin-right: -.625rem;
  margin-left: -.625rem; }

#splash .card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

#splash .card-img {
  width: 100%;
  border-radius: calc(.25rem - 1px); }

#splash .card-img-top {
  width: 100%;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px); }

#splash .card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px); }

#splash .card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

#splash .card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  #splash .card-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
  #splash .card-deck .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px; } }

#splash .card-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

#splash .card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  #splash .card-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; }
  #splash .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0; }
  #splash .card-group > .card + .card {
    margin-left: 0;
    border-left: 0; }
  #splash .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  #splash .card-group > .card:first-child .card-header, #splash .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0; }
  #splash .card-group > .card:first-child .card-footer, #splash .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0; }
  #splash .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  #splash .card-group > .card:last-child .card-header, #splash .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0; }
  #splash .card-group > .card:last-child .card-footer, #splash .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0; }
  #splash .card-group > .card:only-child {
    border-radius: .25rem; }
  #splash .card-group > .card:only-child .card-header, #splash .card-group > .card:only-child .card-img-top {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem; }
  #splash .card-group > .card:only-child .card-footer, #splash .card-group > .card:only-child .card-img-bottom {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem; }
  #splash .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0; }
  #splash .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer, #splash .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header, #splash .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom, #splash .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
    border-radius: 0; } }

#splash .card-columns .card {
  margin-bottom: .75rem; }

@media (min-width: 576px) {
  #splash .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem; }
  #splash .card-columns .card {
    display: inline-block;
    width: 100%; } }

#splash .breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: .25rem; }

#splash .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  padding-left: .5rem;
  color: #868e96;
  content: "/"; }

#splash .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

#splash .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

#splash .breadcrumb-item.active {
  color: #868e96; }

#splash .pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem; }

#splash .page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }

#splash .page-link:focus, #splash .page-link:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6; }

#splash .page-link:not([disabled]):not(.disabled) {
  cursor: pointer; }

#splash .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem; }

#splash .page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem; }

#splash .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

#splash .page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

#splash .pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

#splash .pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem; }

#splash .pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem; }

#splash .pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5; }

#splash .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem; }

#splash .pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem; }

#splash .badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem; }

#splash .badge:empty {
  display: none; }

#splash .btn .badge {
  position: relative;
  top: -1px; }

#splash .badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem; }

#splash .badge-primary {
  color: #fff;
  background-color: #007bff; }

#splash .badge-primary[href]:focus, #splash .badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc; }

#splash .badge-secondary {
  color: #fff;
  background-color: #868e96; }

#splash .badge-secondary[href]:focus, #splash .badge-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #6c757d; }

#splash .badge-success {
  color: #fff;
  background-color: #28a745; }

#splash .badge-success[href]:focus, #splash .badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34; }

#splash .badge-info {
  color: #fff;
  background-color: #17a2b8; }

#splash .badge-info[href]:focus, #splash .badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b; }

#splash .badge-warning {
  color: #212529;
  background-color: #ffc107; }

#splash .badge-warning[href]:focus, #splash .badge-warning[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00; }

#splash .badge-danger {
  color: #fff;
  background-color: #dc3545; }

#splash .badge-danger[href]:focus, #splash .badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130; }

#splash .badge-light {
  color: #212529;
  background-color: #f8f9fa; }

#splash .badge-light[href]:focus, #splash .badge-light[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5; }

#splash .badge-dark {
  color: #fff;
  background-color: #343a40; }

#splash .badge-dark[href]:focus, #splash .badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124; }

#splash .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem; }

@media (min-width: 576px) {
  #splash .jumbotron {
    padding: 4rem 2rem; } }

#splash .jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

#splash .alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem; }

#splash .alert-heading {
  color: inherit; }

#splash .alert-link {
  font-weight: 700; }

#splash .alert-dismissible {
  padding-right: 4rem; }

#splash .alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: .75rem 1.25rem;
  color: inherit; }

#splash .alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }

#splash .alert-primary hr {
  border-top-color: #9fcdff; }

#splash .alert-primary .alert-link {
  color: #002752; }

#splash .alert-secondary {
  color: #464a4e;
  background-color: #e7e8ea;
  border-color: #dddfe2; }

#splash .alert-secondary hr {
  border-top-color: #cfd2d6; }

#splash .alert-secondary .alert-link {
  color: #2e3133; }

#splash .alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }

#splash .alert-success hr {
  border-top-color: #b1dfbb; }

#splash .alert-success .alert-link {
  color: #0b2e13; }

#splash .alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }

#splash .alert-info hr {
  border-top-color: #abdde5; }

#splash .alert-info .alert-link {
  color: #062c33; }

#splash .alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }

#splash .alert-warning hr {
  border-top-color: #ffe8a1; }

#splash .alert-warning .alert-link {
  color: #533f03; }

#splash .alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }

#splash .alert-danger hr {
  border-top-color: #f1b0b7; }

#splash .alert-danger .alert-link {
  color: #491217; }

#splash .alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }

#splash .alert-light hr {
  border-top-color: #ececf6; }

#splash .alert-light .alert-link {
  color: #686868; }

#splash .alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }

#splash .alert-dark hr {
  border-top-color: #b9bbbe; }

#splash .alert-dark .alert-link {
  color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

#splash .progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem; }

#splash .progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width .6s ease; }

#splash .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

#splash .progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite; }

#splash .media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

#splash .media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

#splash .list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

#splash .list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }

#splash .list-group-item-action:focus, #splash .list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa; }

#splash .list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef; }

#splash .list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }

#splash .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

#splash .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

#splash .list-group-item:focus, #splash .list-group-item:hover {
  z-index: 1;
  text-decoration: none; }

#splash .list-group-item.disabled, #splash .list-group-item:disabled {
  color: #868e96;
  background-color: #fff; }

#splash .list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

#splash .list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

#splash .list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

#splash .list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

#splash .list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }

#splash a.list-group-item-primary, #splash button.list-group-item-primary {
  color: #004085; }

#splash a.list-group-item-primary:focus, #splash a.list-group-item-primary:hover, #splash button.list-group-item-primary:focus, #splash button.list-group-item-primary:hover {
  color: #004085;
  background-color: #9fcdff; }

#splash a.list-group-item-primary.active, #splash button.list-group-item-primary.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085; }

#splash .list-group-item-secondary {
  color: #464a4e;
  background-color: #dddfe2; }

#splash a.list-group-item-secondary, #splash button.list-group-item-secondary {
  color: #464a4e; }

#splash a.list-group-item-secondary:focus, #splash a.list-group-item-secondary:hover, #splash button.list-group-item-secondary:focus, #splash button.list-group-item-secondary:hover {
  color: #464a4e;
  background-color: #cfd2d6; }

#splash a.list-group-item-secondary.active, #splash button.list-group-item-secondary.active {
  color: #fff;
  background-color: #464a4e;
  border-color: #464a4e; }

#splash .list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }

#splash a.list-group-item-success, #splash button.list-group-item-success {
  color: #155724; }

#splash a.list-group-item-success:focus, #splash a.list-group-item-success:hover, #splash button.list-group-item-success:focus, #splash button.list-group-item-success:hover {
  color: #155724;
  background-color: #b1dfbb; }

#splash a.list-group-item-success.active, #splash button.list-group-item-success.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724; }

#splash .list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }

#splash a.list-group-item-info, #splash button.list-group-item-info {
  color: #0c5460; }

#splash a.list-group-item-info:focus, #splash a.list-group-item-info:hover, #splash button.list-group-item-info:focus, #splash button.list-group-item-info:hover {
  color: #0c5460;
  background-color: #abdde5; }

#splash a.list-group-item-info.active, #splash button.list-group-item-info.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460; }

#splash .list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }

#splash a.list-group-item-warning, #splash button.list-group-item-warning {
  color: #856404; }

#splash a.list-group-item-warning:focus, #splash a.list-group-item-warning:hover, #splash button.list-group-item-warning:focus, #splash button.list-group-item-warning:hover {
  color: #856404;
  background-color: #ffe8a1; }

#splash a.list-group-item-warning.active, #splash button.list-group-item-warning.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404; }

#splash .list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }

#splash a.list-group-item-danger, #splash button.list-group-item-danger {
  color: #721c24; }

#splash a.list-group-item-danger:focus, #splash a.list-group-item-danger:hover, #splash button.list-group-item-danger:focus, #splash button.list-group-item-danger:hover {
  color: #721c24;
  background-color: #f1b0b7; }

#splash a.list-group-item-danger.active, #splash button.list-group-item-danger.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24; }

#splash .list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }

#splash a.list-group-item-light, #splash button.list-group-item-light {
  color: #818182; }

#splash a.list-group-item-light:focus, #splash a.list-group-item-light:hover, #splash button.list-group-item-light:focus, #splash button.list-group-item-light:hover {
  color: #818182;
  background-color: #ececf6; }

#splash a.list-group-item-light.active, #splash button.list-group-item-light.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182; }

#splash .list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }

#splash a.list-group-item-dark, #splash button.list-group-item-dark {
  color: #1b1e21; }

#splash a.list-group-item-dark:focus, #splash a.list-group-item-dark:hover, #splash button.list-group-item-dark:focus, #splash button.list-group-item-dark:hover {
  color: #1b1e21;
  background-color: #b9bbbe; }

#splash a.list-group-item-dark.active, #splash button.list-group-item-dark.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21; }

#splash .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }

#splash .close:focus, #splash .close:hover {
  color: #000;
  text-decoration: none;
  opacity: .75; }

#splash .close:not([disabled]):not(.disabled) {
  cursor: pointer; }

#splash button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

#splash .modal-open {
  overflow: hidden; }

#splash .modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }

#splash .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

#splash .modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none; }

#splash .modal.fade .modal-dialog {
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out,-webkit-transform .3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%); }

#splash .modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0); }

#splash .modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2)); }

#splash .modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem;
  outline: 0; }

#splash .modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }

#splash .modal-backdrop.fade {
  opacity: 0; }

#splash .modal-backdrop.show {
  opacity: .5; }

#splash .modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem; }

#splash .modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto; }

#splash .modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

#splash .modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem; }

#splash .modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }

#splash .modal-footer > :not(:first-child) {
  margin-left: .25rem; }

#splash .modal-footer > :not(:last-child) {
  margin-right: .25rem; }

#splash .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  #splash .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  #splash .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  #splash .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  #splash .modal-lg {
    max-width: 800px; } }

#splash .tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  opacity: 0; }

#splash .tooltip.show {
  opacity: .9; }

#splash .tooltip .arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem; }

#splash .tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid; }

#splash .bs-tooltip-auto[x-placement^=top], #splash .bs-tooltip-top {
  padding: .4rem 0; }

#splash .bs-tooltip-auto[x-placement^=top] .arrow, #splash .bs-tooltip-top .arrow {
  bottom: 0; }

#splash .bs-tooltip-auto[x-placement^=top] .arrow::before, #splash .bs-tooltip-top .arrow::before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #000; }

#splash .bs-tooltip-auto[x-placement^=right], #splash .bs-tooltip-right {
  padding: 0 .4rem; }

#splash .bs-tooltip-auto[x-placement^=right] .arrow, #splash .bs-tooltip-right .arrow {
  left: 0;
  width: .4rem;
  height: .8rem; }

#splash .bs-tooltip-auto[x-placement^=right] .arrow::before, #splash .bs-tooltip-right .arrow::before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000; }

#splash .bs-tooltip-auto[x-placement^=bottom], #splash .bs-tooltip-bottom {
  padding: .4rem 0; }

#splash .bs-tooltip-auto[x-placement^=bottom] .arrow, #splash .bs-tooltip-bottom .arrow {
  top: 0; }

#splash .bs-tooltip-auto[x-placement^=bottom] .arrow::before, #splash .bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000; }

#splash .bs-tooltip-auto[x-placement^=left], #splash .bs-tooltip-left {
  padding: 0 .4rem; }

#splash .bs-tooltip-auto[x-placement^=left] .arrow, #splash .bs-tooltip-left .arrow {
  right: 0;
  width: .4rem;
  height: .8rem; }

#splash .bs-tooltip-auto[x-placement^=left] .arrow::before, #splash .bs-tooltip-left .arrow::before {
  left: 0;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000; }

#splash .tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem; }

#splash .popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem; }

#splash .popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem; }

#splash .popover .arrow::after, #splash .popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

#splash .bs-popover-auto[x-placement^=top], #splash .bs-popover-top {
  margin-bottom: .5rem; }

#splash .bs-popover-auto[x-placement^=top] .arrow, #splash .bs-popover-top .arrow {
  bottom: calc((.5rem + 1px) * -1); }

#splash .bs-popover-auto[x-placement^=top] .arrow::after, #splash .bs-popover-auto[x-placement^=top] .arrow::before, #splash .bs-popover-top .arrow::after, #splash .bs-popover-top .arrow::before {
  border-width: .5rem .5rem 0; }

#splash .bs-popover-auto[x-placement^=top] .arrow::before, #splash .bs-popover-top .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25); }

#splash .bs-popover-auto[x-placement^=top] .arrow::after, #splash .bs-popover-top .arrow::after {
  bottom: 1px;
  border-top-color: #fff; }

#splash .bs-popover-auto[x-placement^=right], #splash .bs-popover-right {
  margin-left: .5rem; }

#splash .bs-popover-auto[x-placement^=right] .arrow, #splash .bs-popover-right .arrow {
  left: calc((.5rem + 1px) * -1);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0; }

#splash .bs-popover-auto[x-placement^=right] .arrow::after, #splash .bs-popover-auto[x-placement^=right] .arrow::before, #splash .bs-popover-right .arrow::after, #splash .bs-popover-right .arrow::before {
  border-width: .5rem .5rem .5rem 0; }

#splash .bs-popover-auto[x-placement^=right] .arrow::before, #splash .bs-popover-right .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25); }

#splash .bs-popover-auto[x-placement^=right] .arrow::after, #splash .bs-popover-right .arrow::after {
  left: 1px;
  border-right-color: #fff; }

#splash .bs-popover-auto[x-placement^=bottom], #splash .bs-popover-bottom {
  margin-top: .5rem; }

#splash .bs-popover-auto[x-placement^=bottom] .arrow, #splash .bs-popover-bottom .arrow {
  top: calc((.5rem + 1px) * -1); }

#splash .bs-popover-auto[x-placement^=bottom] .arrow::after, #splash .bs-popover-auto[x-placement^=bottom] .arrow::before, #splash .bs-popover-bottom .arrow::after, #splash .bs-popover-bottom .arrow::before {
  border-width: 0 .5rem .5rem .5rem; }

#splash .bs-popover-auto[x-placement^=bottom] .arrow::before, #splash .bs-popover-bottom .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

#splash .bs-popover-auto[x-placement^=bottom] .arrow::after, #splash .bs-popover-bottom .arrow::after {
  top: 1px;
  border-bottom-color: #fff; }

#splash .bs-popover-auto[x-placement^=bottom] .popover-header::before, #splash .bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7; }

#splash .bs-popover-auto[x-placement^=left], #splash .bs-popover-left {
  margin-right: .5rem; }

#splash .bs-popover-auto[x-placement^=left] .arrow, #splash .bs-popover-left .arrow {
  right: calc((.5rem + 1px) * -1);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0; }

#splash .bs-popover-auto[x-placement^=left] .arrow::after, #splash .bs-popover-auto[x-placement^=left] .arrow::before, #splash .bs-popover-left .arrow::after, #splash .bs-popover-left .arrow::before {
  border-width: .5rem 0 .5rem .5rem; }

#splash .bs-popover-auto[x-placement^=left] .arrow::before, #splash .bs-popover-left .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25); }

#splash .bs-popover-auto[x-placement^=left] .arrow::after, #splash .bs-popover-left .arrow::after {
  right: 1px;
  border-left-color: #fff; }

#splash .popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px); }

#splash .popover-header:empty {
  display: none; }

#splash .popover-body {
  padding: .5rem .75rem;
  color: #212529; }

#splash .carousel {
  position: relative; }

#splash .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

#splash .carousel-item {
  position: relative;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  transition: -webkit-transform .6s ease;
  transition: transform .6s ease;
  transition: transform .6s ease,-webkit-transform .6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px; }

#splash .carousel-item-next, #splash .carousel-item-prev, #splash .carousel-item.active {
  display: block; }

#splash .carousel-item-next, #splash .carousel-item-prev {
  position: absolute;
  top: 0; }

#splash .carousel-item-next.carousel-item-left, #splash .carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  transform: translateX(0); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  #splash .carousel-item-next.carousel-item-left, #splash .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

#splash .active.carousel-item-right, #splash .carousel-item-next {
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  #splash .active.carousel-item-right, #splash .carousel-item-next {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

#splash .active.carousel-item-left, #splash .carousel-item-prev {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  #splash .active.carousel-item-left, #splash .carousel-item-prev {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

#splash .carousel-control-next, #splash .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5; }

#splash .carousel-control-next:focus, #splash .carousel-control-next:hover, #splash .carousel-control-prev:focus, #splash .carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9; }

#splash .carousel-control-prev {
  left: 0; }

#splash .carousel-control-next {
  right: 0; }

#splash .carousel-control-next-icon, #splash .carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

#splash .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

#splash .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

#splash .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }

#splash .carousel-indicators li {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5); }

#splash .carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: ""; }

#splash .carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: ""; }

#splash .carousel-indicators .active {
  background-color: #fff; }

#splash .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

#splash .align-baseline {
  vertical-align: baseline !important; }

#splash .align-top {
  vertical-align: top !important; }

#splash .align-middle {
  vertical-align: middle !important; }

#splash .align-bottom {
  vertical-align: bottom !important; }

#splash .align-text-bottom {
  vertical-align: text-bottom !important; }

#splash .align-text-top {
  vertical-align: text-top !important; }

#splash .bg-primary {
  background-color: #007bff !important; }

#splash a.bg-primary:focus, #splash a.bg-primary:hover, #splash button.bg-primary:focus, #splash button.bg-primary:hover {
  background-color: #0062cc !important; }

#splash .bg-secondary {
  background-color: #868e96 !important; }

#splash a.bg-secondary:focus, #splash a.bg-secondary:hover, #splash button.bg-secondary:focus, #splash button.bg-secondary:hover {
  background-color: #6c757d !important; }

#splash .bg-success {
  background-color: #28a745 !important; }

#splash a.bg-success:focus, #splash a.bg-success:hover, #splash button.bg-success:focus, #splash button.bg-success:hover {
  background-color: #1e7e34 !important; }

#splash .bg-info {
  background-color: #17a2b8 !important; }

#splash a.bg-info:focus, #splash a.bg-info:hover, #splash button.bg-info:focus, #splash button.bg-info:hover {
  background-color: #117a8b !important; }

#splash .bg-warning {
  background-color: #ffc107 !important; }

#splash a.bg-warning:focus, #splash a.bg-warning:hover, #splash button.bg-warning:focus, #splash button.bg-warning:hover {
  background-color: #d39e00 !important; }

#splash .bg-danger {
  background-color: #dc3545 !important; }

#splash a.bg-danger:focus, #splash a.bg-danger:hover, #splash button.bg-danger:focus, #splash button.bg-danger:hover {
  background-color: #bd2130 !important; }

#splash .bg-light {
  background-color: #f8f9fa !important; }

#splash a.bg-light:focus, #splash a.bg-light:hover, #splash button.bg-light:focus, #splash button.bg-light:hover {
  background-color: #dae0e5 !important; }

#splash .bg-dark {
  background-color: #343a40 !important; }

#splash a.bg-dark:focus, #splash a.bg-dark:hover, #splash button.bg-dark:focus, #splash button.bg-dark:hover {
  background-color: #1d2124 !important; }

#splash .bg-white {
  background-color: #fff !important; }

#splash .bg-transparent {
  background-color: transparent !important; }

#splash .border {
  border: 1px solid #e9ecef !important; }

#splash .border-0 {
  border: 0 !important; }

#splash .border-top-0 {
  border-top: 0 !important; }

#splash .border-right-0 {
  border-right: 0 !important; }

#splash .border-bottom-0 {
  border-bottom: 0 !important; }

#splash .border-left-0 {
  border-left: 0 !important; }

#splash .border-primary {
  border-color: #007bff !important; }

#splash .border-secondary {
  border-color: #868e96 !important; }

#splash .border-success {
  border-color: #28a745 !important; }

#splash .border-info {
  border-color: #17a2b8 !important; }

#splash .border-warning {
  border-color: #ffc107 !important; }

#splash .border-danger {
  border-color: #dc3545 !important; }

#splash .border-light {
  border-color: #f8f9fa !important; }

#splash .border-dark {
  border-color: #343a40 !important; }

#splash .border-white {
  border-color: #fff !important; }

#splash .rounded {
  border-radius: 0.25rem !important; }

#splash .rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

#splash .rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

#splash .rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

#splash .rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

#splash .rounded-circle {
  border-radius: 50% !important; }

#splash .rounded-0 {
  border-radius: 0 !important; }

#splash .clearfix::after {
  display: block;
  clear: both;
  content: ""; }

#splash .d-none {
  display: none !important; }

#splash .d-inline {
  display: inline !important; }

#splash .d-inline-block {
  display: inline-block !important; }

#splash .d-block {
  display: block !important; }

#splash .d-table {
  display: table !important; }

#splash .d-table-row {
  display: table-row !important; }

#splash .d-table-cell {
  display: table-cell !important; }

#splash .d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

#splash .d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  #splash .d-sm-none {
    display: none !important; }
  #splash .d-sm-inline {
    display: inline !important; }
  #splash .d-sm-inline-block {
    display: inline-block !important; }
  #splash .d-sm-block {
    display: block !important; }
  #splash .d-sm-table {
    display: table !important; }
  #splash .d-sm-table-row {
    display: table-row !important; }
  #splash .d-sm-table-cell {
    display: table-cell !important; }
  #splash .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  #splash .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  #splash .d-md-none {
    display: none !important; }
  #splash .d-md-inline {
    display: inline !important; }
  #splash .d-md-inline-block {
    display: inline-block !important; }
  #splash .d-md-block {
    display: block !important; }
  #splash .d-md-table {
    display: table !important; }
  #splash .d-md-table-row {
    display: table-row !important; }
  #splash .d-md-table-cell {
    display: table-cell !important; }
  #splash .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  #splash .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  #splash .d-lg-none {
    display: none !important; }
  #splash .d-lg-inline {
    display: inline !important; }
  #splash .d-lg-inline-block {
    display: inline-block !important; }
  #splash .d-lg-block {
    display: block !important; }
  #splash .d-lg-table {
    display: table !important; }
  #splash .d-lg-table-row {
    display: table-row !important; }
  #splash .d-lg-table-cell {
    display: table-cell !important; }
  #splash .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  #splash .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  #splash .d-xl-none {
    display: none !important; }
  #splash .d-xl-inline {
    display: inline !important; }
  #splash .d-xl-inline-block {
    display: inline-block !important; }
  #splash .d-xl-block {
    display: block !important; }
  #splash .d-xl-table {
    display: table !important; }
  #splash .d-xl-table-row {
    display: table-row !important; }
  #splash .d-xl-table-cell {
    display: table-cell !important; }
  #splash .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  #splash .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

#splash .d-print-block {
  display: none !important; }

@media print {
  #splash .d-print-block {
    display: block !important; } }

#splash .d-print-inline {
  display: none !important; }

@media print {
  #splash .d-print-inline {
    display: inline !important; } }

#splash .d-print-inline-block {
  display: none !important; }

@media print {
  #splash .d-print-inline-block {
    display: inline-block !important; } }

@media print {
  #splash .d-print-none {
    display: none !important; } }

#splash .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }

#splash .embed-responsive::before {
  display: block;
  content: ""; }

#splash .embed-responsive .embed-responsive-item, #splash .embed-responsive embed, #splash .embed-responsive iframe, #splash .embed-responsive object, #splash .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

#splash .embed-responsive-21by9::before {
  padding-top: 42.857143%; }

#splash .embed-responsive-16by9::before {
  padding-top: 56.25%; }

#splash .embed-responsive-4by3::before {
  padding-top: 75%; }

#splash .embed-responsive-1by1::before {
  padding-top: 100%; }

#splash .flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

#splash .flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

#splash .flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

#splash .flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

#splash .flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

#splash .flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

#splash .flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

#splash .justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

#splash .justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

#splash .justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important; }

#splash .justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

#splash .justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

#splash .align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

#splash .align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

#splash .align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important; }

#splash .align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

#splash .align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

#splash .align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

#splash .align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

#splash .align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

#splash .align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

#splash .align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

#splash .align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

#splash .align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

#splash .align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

#splash .align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

#splash .align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

#splash .align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

#splash .align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  #splash .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  #splash .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  #splash .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  #splash .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  #splash .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  #splash .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  #splash .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  #splash .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  #splash .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  #splash .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  #splash .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  #splash .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  #splash .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  #splash .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  #splash .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  #splash .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  #splash .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  #splash .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  #splash .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  #splash .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  #splash .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  #splash .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  #splash .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  #splash .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  #splash .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  #splash .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  #splash .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  #splash .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  #splash .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  #splash .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  #splash .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  #splash .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  #splash .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  #splash .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  #splash .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  #splash .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  #splash .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  #splash .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  #splash .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  #splash .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  #splash .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  #splash .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  #splash .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  #splash .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  #splash .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  #splash .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  #splash .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  #splash .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  #splash .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  #splash .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  #splash .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  #splash .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  #splash .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  #splash .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  #splash .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  #splash .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  #splash .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  #splash .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  #splash .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  #splash .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  #splash .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  #splash .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  #splash .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  #splash .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  #splash .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  #splash .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  #splash .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  #splash .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  #splash .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  #splash .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  #splash .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  #splash .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  #splash .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  #splash .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  #splash .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  #splash .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  #splash .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  #splash .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  #splash .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  #splash .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  #splash .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  #splash .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  #splash .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  #splash .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  #splash .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  #splash .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  #splash .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  #splash .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  #splash .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  #splash .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  #splash .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  #splash .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  #splash .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  #splash .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  #splash .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  #splash .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  #splash .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  #splash .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  #splash .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  #splash .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  #splash .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  #splash .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  #splash .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  #splash .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  #splash .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  #splash .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  #splash .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  #splash .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  #splash .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  #splash .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  #splash .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  #splash .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  #splash .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  #splash .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  #splash .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  #splash .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

#splash .float-left {
  float: left !important; }

#splash .float-right {
  float: right !important; }

#splash .float-none {
  float: none !important; }

@media (min-width: 576px) {
  #splash .float-sm-left {
    float: left !important; }
  #splash .float-sm-right {
    float: right !important; }
  #splash .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  #splash .float-md-left {
    float: left !important; }
  #splash .float-md-right {
    float: right !important; }
  #splash .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  #splash .float-lg-left {
    float: left !important; }
  #splash .float-lg-right {
    float: right !important; }
  #splash .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  #splash .float-xl-left {
    float: left !important; }
  #splash .float-xl-right {
    float: right !important; }
  #splash .float-xl-none {
    float: none !important; } }

#splash .position-static {
  position: static !important; }

#splash .position-relative {
  position: relative !important; }

#splash .position-absolute {
  position: absolute !important; }

#splash .position-fixed {
  position: fixed !important; }

#splash .position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

#splash .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

#splash .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: -webkit-sticky) or (position: sticky) {
  #splash .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

#splash .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0; }

#splash .sr-only-focusable:active, #splash .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none; }

#splash .w-25 {
  width: 25% !important; }

#splash .w-50 {
  width: 50% !important; }

#splash .w-75 {
  width: 75% !important; }

#splash .w-100 {
  width: 100% !important; }

#splash .h-25 {
  height: 25% !important; }

#splash .h-50 {
  height: 50% !important; }

#splash .h-75 {
  height: 75% !important; }

#splash .h-100 {
  height: 100% !important; }

#splash .mw-100 {
  max-width: 100% !important; }

#splash .mh-100 {
  max-height: 100% !important; }

#splash .m-0 {
  margin: 0 !important; }

#splash .mt-0, #splash .my-0 {
  margin-top: 0 !important; }

#splash .mr-0, #splash .mx-0 {
  margin-right: 0 !important; }

#splash .mb-0, #splash .my-0 {
  margin-bottom: 0 !important; }

#splash .ml-0, #splash .mx-0 {
  margin-left: 0 !important; }

#splash .m-1 {
  margin: 0.25rem !important; }

#splash .mt-1, #splash .my-1 {
  margin-top: 0.25rem !important; }

#splash .mr-1, #splash .mx-1 {
  margin-right: 0.25rem !important; }

#splash .mb-1, #splash .my-1 {
  margin-bottom: 0.25rem !important; }

#splash .ml-1, #splash .mx-1 {
  margin-left: 0.25rem !important; }

#splash .m-2 {
  margin: 0.5rem !important; }

#splash .mt-2, #splash .my-2 {
  margin-top: 0.5rem !important; }

#splash .mr-2, #splash .mx-2 {
  margin-right: 0.5rem !important; }

#splash .mb-2, #splash .my-2 {
  margin-bottom: 0.5rem !important; }

#splash .ml-2, #splash .mx-2 {
  margin-left: 0.5rem !important; }

#splash .m-3 {
  margin: 1rem !important; }

#splash .mt-3, #splash .my-3 {
  margin-top: 1rem !important; }

#splash .mr-3, #splash .mx-3 {
  margin-right: 1rem !important; }

#splash .mb-3, #splash .my-3 {
  margin-bottom: 1rem !important; }

#splash .ml-3, #splash .mx-3 {
  margin-left: 1rem !important; }

#splash .m-4 {
  margin: 1.5rem !important; }

#splash .mt-4, #splash .my-4 {
  margin-top: 1.5rem !important; }

#splash .mr-4, #splash .mx-4 {
  margin-right: 1.5rem !important; }

#splash .mb-4, #splash .my-4 {
  margin-bottom: 1.5rem !important; }

#splash .ml-4, #splash .mx-4 {
  margin-left: 1.5rem !important; }

#splash .m-5 {
  margin: 3rem !important; }

#splash .mt-5, #splash .my-5 {
  margin-top: 3rem !important; }

#splash .mr-5, #splash .mx-5 {
  margin-right: 3rem !important; }

#splash .mb-5, #splash .my-5 {
  margin-bottom: 3rem !important; }

#splash .ml-5, #splash .mx-5 {
  margin-left: 3rem !important; }

#splash .p-0 {
  padding: 0 !important; }

#splash .pt-0, #splash .py-0 {
  padding-top: 0 !important; }

#splash .pr-0, #splash .px-0 {
  padding-right: 0 !important; }

#splash .pb-0, #splash .py-0 {
  padding-bottom: 0 !important; }

#splash .pl-0, #splash .px-0 {
  padding-left: 0 !important; }

#splash .p-1 {
  padding: 0.25rem !important; }

#splash .pt-1, #splash .py-1 {
  padding-top: 0.25rem !important; }

#splash .pr-1, #splash .px-1 {
  padding-right: 0.25rem !important; }

#splash .pb-1, #splash .py-1 {
  padding-bottom: 0.25rem !important; }

#splash .pl-1, #splash .px-1 {
  padding-left: 0.25rem !important; }

#splash .p-2 {
  padding: 0.5rem !important; }

#splash .pt-2, #splash .py-2 {
  padding-top: 0.5rem !important; }

#splash .pr-2, #splash .px-2 {
  padding-right: 0.5rem !important; }

#splash .pb-2, #splash .py-2 {
  padding-bottom: 0.5rem !important; }

#splash .pl-2, #splash .px-2 {
  padding-left: 0.5rem !important; }

#splash .p-3 {
  padding: 1rem !important; }

#splash .pt-3, #splash .py-3 {
  padding-top: 1rem !important; }

#splash .pr-3, #splash .px-3 {
  padding-right: 1rem !important; }

#splash .pb-3, #splash .py-3 {
  padding-bottom: 1rem !important; }

#splash .pl-3, #splash .px-3 {
  padding-left: 1rem !important; }

#splash .p-4 {
  padding: 1.5rem !important; }

#splash .pt-4, #splash .py-4 {
  padding-top: 1.5rem !important; }

#splash .pr-4, #splash .px-4 {
  padding-right: 1.5rem !important; }

#splash .pb-4, #splash .py-4 {
  padding-bottom: 1.5rem !important; }

#splash .pl-4, #splash .px-4 {
  padding-left: 1.5rem !important; }

#splash .p-5 {
  padding: 3rem !important; }

#splash .pt-5, #splash .py-5 {
  padding-top: 3rem !important; }

#splash .pr-5, #splash .px-5 {
  padding-right: 3rem !important; }

#splash .pb-5, #splash .py-5 {
  padding-bottom: 3rem !important; }

#splash .pl-5, #splash .px-5 {
  padding-left: 3rem !important; }

#splash .m-auto {
  margin: auto !important; }

#splash .mt-auto, #splash .my-auto {
  margin-top: auto !important; }

#splash .mr-auto, #splash .mx-auto {
  margin-right: auto !important; }

#splash .mb-auto, #splash .my-auto {
  margin-bottom: auto !important; }

#splash .ml-auto, #splash .mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  #splash .m-sm-0 {
    margin: 0 !important; }
  #splash .mt-sm-0, #splash .my-sm-0 {
    margin-top: 0 !important; }
  #splash .mr-sm-0, #splash .mx-sm-0 {
    margin-right: 0 !important; }
  #splash .mb-sm-0, #splash .my-sm-0 {
    margin-bottom: 0 !important; }
  #splash .ml-sm-0, #splash .mx-sm-0 {
    margin-left: 0 !important; }
  #splash .m-sm-1 {
    margin: 0.25rem !important; }
  #splash .mt-sm-1, #splash .my-sm-1 {
    margin-top: 0.25rem !important; }
  #splash .mr-sm-1, #splash .mx-sm-1 {
    margin-right: 0.25rem !important; }
  #splash .mb-sm-1, #splash .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  #splash .ml-sm-1, #splash .mx-sm-1 {
    margin-left: 0.25rem !important; }
  #splash .m-sm-2 {
    margin: 0.5rem !important; }
  #splash .mt-sm-2, #splash .my-sm-2 {
    margin-top: 0.5rem !important; }
  #splash .mr-sm-2, #splash .mx-sm-2 {
    margin-right: 0.5rem !important; }
  #splash .mb-sm-2, #splash .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  #splash .ml-sm-2, #splash .mx-sm-2 {
    margin-left: 0.5rem !important; }
  #splash .m-sm-3 {
    margin: 1rem !important; }
  #splash .mt-sm-3, #splash .my-sm-3 {
    margin-top: 1rem !important; }
  #splash .mr-sm-3, #splash .mx-sm-3 {
    margin-right: 1rem !important; }
  #splash .mb-sm-3, #splash .my-sm-3 {
    margin-bottom: 1rem !important; }
  #splash .ml-sm-3, #splash .mx-sm-3 {
    margin-left: 1rem !important; }
  #splash .m-sm-4 {
    margin: 1.5rem !important; }
  #splash .mt-sm-4, #splash .my-sm-4 {
    margin-top: 1.5rem !important; }
  #splash .mr-sm-4, #splash .mx-sm-4 {
    margin-right: 1.5rem !important; }
  #splash .mb-sm-4, #splash .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  #splash .ml-sm-4, #splash .mx-sm-4 {
    margin-left: 1.5rem !important; }
  #splash .m-sm-5 {
    margin: 3rem !important; }
  #splash .mt-sm-5, #splash .my-sm-5 {
    margin-top: 3rem !important; }
  #splash .mr-sm-5, #splash .mx-sm-5 {
    margin-right: 3rem !important; }
  #splash .mb-sm-5, #splash .my-sm-5 {
    margin-bottom: 3rem !important; }
  #splash .ml-sm-5, #splash .mx-sm-5 {
    margin-left: 3rem !important; }
  #splash .p-sm-0 {
    padding: 0 !important; }
  #splash .pt-sm-0, #splash .py-sm-0 {
    padding-top: 0 !important; }
  #splash .pr-sm-0, #splash .px-sm-0 {
    padding-right: 0 !important; }
  #splash .pb-sm-0, #splash .py-sm-0 {
    padding-bottom: 0 !important; }
  #splash .pl-sm-0, #splash .px-sm-0 {
    padding-left: 0 !important; }
  #splash .p-sm-1 {
    padding: 0.25rem !important; }
  #splash .pt-sm-1, #splash .py-sm-1 {
    padding-top: 0.25rem !important; }
  #splash .pr-sm-1, #splash .px-sm-1 {
    padding-right: 0.25rem !important; }
  #splash .pb-sm-1, #splash .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  #splash .pl-sm-1, #splash .px-sm-1 {
    padding-left: 0.25rem !important; }
  #splash .p-sm-2 {
    padding: 0.5rem !important; }
  #splash .pt-sm-2, #splash .py-sm-2 {
    padding-top: 0.5rem !important; }
  #splash .pr-sm-2, #splash .px-sm-2 {
    padding-right: 0.5rem !important; }
  #splash .pb-sm-2, #splash .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  #splash .pl-sm-2, #splash .px-sm-2 {
    padding-left: 0.5rem !important; }
  #splash .p-sm-3 {
    padding: 1rem !important; }
  #splash .pt-sm-3, #splash .py-sm-3 {
    padding-top: 1rem !important; }
  #splash .pr-sm-3, #splash .px-sm-3 {
    padding-right: 1rem !important; }
  #splash .pb-sm-3, #splash .py-sm-3 {
    padding-bottom: 1rem !important; }
  #splash .pl-sm-3, #splash .px-sm-3 {
    padding-left: 1rem !important; }
  #splash .p-sm-4 {
    padding: 1.5rem !important; }
  #splash .pt-sm-4, #splash .py-sm-4 {
    padding-top: 1.5rem !important; }
  #splash .pr-sm-4, #splash .px-sm-4 {
    padding-right: 1.5rem !important; }
  #splash .pb-sm-4, #splash .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  #splash .pl-sm-4, #splash .px-sm-4 {
    padding-left: 1.5rem !important; }
  #splash .p-sm-5 {
    padding: 3rem !important; }
  #splash .pt-sm-5, #splash .py-sm-5 {
    padding-top: 3rem !important; }
  #splash .pr-sm-5, #splash .px-sm-5 {
    padding-right: 3rem !important; }
  #splash .pb-sm-5, #splash .py-sm-5 {
    padding-bottom: 3rem !important; }
  #splash .pl-sm-5, #splash .px-sm-5 {
    padding-left: 3rem !important; }
  #splash .m-sm-auto {
    margin: auto !important; }
  #splash .mt-sm-auto, #splash .my-sm-auto {
    margin-top: auto !important; }
  #splash .mr-sm-auto, #splash .mx-sm-auto {
    margin-right: auto !important; }
  #splash .mb-sm-auto, #splash .my-sm-auto {
    margin-bottom: auto !important; }
  #splash .ml-sm-auto, #splash .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  #splash .m-md-0 {
    margin: 0 !important; }
  #splash .mt-md-0, #splash .my-md-0 {
    margin-top: 0 !important; }
  #splash .mr-md-0, #splash .mx-md-0 {
    margin-right: 0 !important; }
  #splash .mb-md-0, #splash .my-md-0 {
    margin-bottom: 0 !important; }
  #splash .ml-md-0, #splash .mx-md-0 {
    margin-left: 0 !important; }
  #splash .m-md-1 {
    margin: 0.25rem !important; }
  #splash .mt-md-1, #splash .my-md-1 {
    margin-top: 0.25rem !important; }
  #splash .mr-md-1, #splash .mx-md-1 {
    margin-right: 0.25rem !important; }
  #splash .mb-md-1, #splash .my-md-1 {
    margin-bottom: 0.25rem !important; }
  #splash .ml-md-1, #splash .mx-md-1 {
    margin-left: 0.25rem !important; }
  #splash .m-md-2 {
    margin: 0.5rem !important; }
  #splash .mt-md-2, #splash .my-md-2 {
    margin-top: 0.5rem !important; }
  #splash .mr-md-2, #splash .mx-md-2 {
    margin-right: 0.5rem !important; }
  #splash .mb-md-2, #splash .my-md-2 {
    margin-bottom: 0.5rem !important; }
  #splash .ml-md-2, #splash .mx-md-2 {
    margin-left: 0.5rem !important; }
  #splash .m-md-3 {
    margin: 1rem !important; }
  #splash .mt-md-3, #splash .my-md-3 {
    margin-top: 1rem !important; }
  #splash .mr-md-3, #splash .mx-md-3 {
    margin-right: 1rem !important; }
  #splash .mb-md-3, #splash .my-md-3 {
    margin-bottom: 1rem !important; }
  #splash .ml-md-3, #splash .mx-md-3 {
    margin-left: 1rem !important; }
  #splash .m-md-4 {
    margin: 1.5rem !important; }
  #splash .mt-md-4, #splash .my-md-4 {
    margin-top: 1.5rem !important; }
  #splash .mr-md-4, #splash .mx-md-4 {
    margin-right: 1.5rem !important; }
  #splash .mb-md-4, #splash .my-md-4 {
    margin-bottom: 1.5rem !important; }
  #splash .ml-md-4, #splash .mx-md-4 {
    margin-left: 1.5rem !important; }
  #splash .m-md-5 {
    margin: 3rem !important; }
  #splash .mt-md-5, #splash .my-md-5 {
    margin-top: 3rem !important; }
  #splash .mr-md-5, #splash .mx-md-5 {
    margin-right: 3rem !important; }
  #splash .mb-md-5, #splash .my-md-5 {
    margin-bottom: 3rem !important; }
  #splash .ml-md-5, #splash .mx-md-5 {
    margin-left: 3rem !important; }
  #splash .p-md-0 {
    padding: 0 !important; }
  #splash .pt-md-0, #splash .py-md-0 {
    padding-top: 0 !important; }
  #splash .pr-md-0, #splash .px-md-0 {
    padding-right: 0 !important; }
  #splash .pb-md-0, #splash .py-md-0 {
    padding-bottom: 0 !important; }
  #splash .pl-md-0, #splash .px-md-0 {
    padding-left: 0 !important; }
  #splash .p-md-1 {
    padding: 0.25rem !important; }
  #splash .pt-md-1, #splash .py-md-1 {
    padding-top: 0.25rem !important; }
  #splash .pr-md-1, #splash .px-md-1 {
    padding-right: 0.25rem !important; }
  #splash .pb-md-1, #splash .py-md-1 {
    padding-bottom: 0.25rem !important; }
  #splash .pl-md-1, #splash .px-md-1 {
    padding-left: 0.25rem !important; }
  #splash .p-md-2 {
    padding: 0.5rem !important; }
  #splash .pt-md-2, #splash .py-md-2 {
    padding-top: 0.5rem !important; }
  #splash .pr-md-2, #splash .px-md-2 {
    padding-right: 0.5rem !important; }
  #splash .pb-md-2, #splash .py-md-2 {
    padding-bottom: 0.5rem !important; }
  #splash .pl-md-2, #splash .px-md-2 {
    padding-left: 0.5rem !important; }
  #splash .p-md-3 {
    padding: 1rem !important; }
  #splash .pt-md-3, #splash .py-md-3 {
    padding-top: 1rem !important; }
  #splash .pr-md-3, #splash .px-md-3 {
    padding-right: 1rem !important; }
  #splash .pb-md-3, #splash .py-md-3 {
    padding-bottom: 1rem !important; }
  #splash .pl-md-3, #splash .px-md-3 {
    padding-left: 1rem !important; }
  #splash .p-md-4 {
    padding: 1.5rem !important; }
  #splash .pt-md-4, #splash .py-md-4 {
    padding-top: 1.5rem !important; }
  #splash .pr-md-4, #splash .px-md-4 {
    padding-right: 1.5rem !important; }
  #splash .pb-md-4, #splash .py-md-4 {
    padding-bottom: 1.5rem !important; }
  #splash .pl-md-4, #splash .px-md-4 {
    padding-left: 1.5rem !important; }
  #splash .p-md-5 {
    padding: 3rem !important; }
  #splash .pt-md-5, #splash .py-md-5 {
    padding-top: 3rem !important; }
  #splash .pr-md-5, #splash .px-md-5 {
    padding-right: 3rem !important; }
  #splash .pb-md-5, #splash .py-md-5 {
    padding-bottom: 3rem !important; }
  #splash .pl-md-5, #splash .px-md-5 {
    padding-left: 3rem !important; }
  #splash .m-md-auto {
    margin: auto !important; }
  #splash .mt-md-auto, #splash .my-md-auto {
    margin-top: auto !important; }
  #splash .mr-md-auto, #splash .mx-md-auto {
    margin-right: auto !important; }
  #splash .mb-md-auto, #splash .my-md-auto {
    margin-bottom: auto !important; }
  #splash .ml-md-auto, #splash .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  #splash .m-lg-0 {
    margin: 0 !important; }
  #splash .mt-lg-0, #splash .my-lg-0 {
    margin-top: 0 !important; }
  #splash .mr-lg-0, #splash .mx-lg-0 {
    margin-right: 0 !important; }
  #splash .mb-lg-0, #splash .my-lg-0 {
    margin-bottom: 0 !important; }
  #splash .ml-lg-0, #splash .mx-lg-0 {
    margin-left: 0 !important; }
  #splash .m-lg-1 {
    margin: 0.25rem !important; }
  #splash .mt-lg-1, #splash .my-lg-1 {
    margin-top: 0.25rem !important; }
  #splash .mr-lg-1, #splash .mx-lg-1 {
    margin-right: 0.25rem !important; }
  #splash .mb-lg-1, #splash .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  #splash .ml-lg-1, #splash .mx-lg-1 {
    margin-left: 0.25rem !important; }
  #splash .m-lg-2 {
    margin: 0.5rem !important; }
  #splash .mt-lg-2, #splash .my-lg-2 {
    margin-top: 0.5rem !important; }
  #splash .mr-lg-2, #splash .mx-lg-2 {
    margin-right: 0.5rem !important; }
  #splash .mb-lg-2, #splash .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  #splash .ml-lg-2, #splash .mx-lg-2 {
    margin-left: 0.5rem !important; }
  #splash .m-lg-3 {
    margin: 1rem !important; }
  #splash .mt-lg-3, #splash .my-lg-3 {
    margin-top: 1rem !important; }
  #splash .mr-lg-3, #splash .mx-lg-3 {
    margin-right: 1rem !important; }
  #splash .mb-lg-3, #splash .my-lg-3 {
    margin-bottom: 1rem !important; }
  #splash .ml-lg-3, #splash .mx-lg-3 {
    margin-left: 1rem !important; }
  #splash .m-lg-4 {
    margin: 1.5rem !important; }
  #splash .mt-lg-4, #splash .my-lg-4 {
    margin-top: 1.5rem !important; }
  #splash .mr-lg-4, #splash .mx-lg-4 {
    margin-right: 1.5rem !important; }
  #splash .mb-lg-4, #splash .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  #splash .ml-lg-4, #splash .mx-lg-4 {
    margin-left: 1.5rem !important; }
  #splash .m-lg-5 {
    margin: 3rem !important; }
  #splash .mt-lg-5, #splash .my-lg-5 {
    margin-top: 3rem !important; }
  #splash .mr-lg-5, #splash .mx-lg-5 {
    margin-right: 3rem !important; }
  #splash .mb-lg-5, #splash .my-lg-5 {
    margin-bottom: 3rem !important; }
  #splash .ml-lg-5, #splash .mx-lg-5 {
    margin-left: 3rem !important; }
  #splash .p-lg-0 {
    padding: 0 !important; }
  #splash .pt-lg-0, #splash .py-lg-0 {
    padding-top: 0 !important; }
  #splash .pr-lg-0, #splash .px-lg-0 {
    padding-right: 0 !important; }
  #splash .pb-lg-0, #splash .py-lg-0 {
    padding-bottom: 0 !important; }
  #splash .pl-lg-0, #splash .px-lg-0 {
    padding-left: 0 !important; }
  #splash .p-lg-1 {
    padding: 0.25rem !important; }
  #splash .pt-lg-1, #splash .py-lg-1 {
    padding-top: 0.25rem !important; }
  #splash .pr-lg-1, #splash .px-lg-1 {
    padding-right: 0.25rem !important; }
  #splash .pb-lg-1, #splash .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  #splash .pl-lg-1, #splash .px-lg-1 {
    padding-left: 0.25rem !important; }
  #splash .p-lg-2 {
    padding: 0.5rem !important; }
  #splash .pt-lg-2, #splash .py-lg-2 {
    padding-top: 0.5rem !important; }
  #splash .pr-lg-2, #splash .px-lg-2 {
    padding-right: 0.5rem !important; }
  #splash .pb-lg-2, #splash .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  #splash .pl-lg-2, #splash .px-lg-2 {
    padding-left: 0.5rem !important; }
  #splash .p-lg-3 {
    padding: 1rem !important; }
  #splash .pt-lg-3, #splash .py-lg-3 {
    padding-top: 1rem !important; }
  #splash .pr-lg-3, #splash .px-lg-3 {
    padding-right: 1rem !important; }
  #splash .pb-lg-3, #splash .py-lg-3 {
    padding-bottom: 1rem !important; }
  #splash .pl-lg-3, #splash .px-lg-3 {
    padding-left: 1rem !important; }
  #splash .p-lg-4 {
    padding: 1.5rem !important; }
  #splash .pt-lg-4, #splash .py-lg-4 {
    padding-top: 1.5rem !important; }
  #splash .pr-lg-4, #splash .px-lg-4 {
    padding-right: 1.5rem !important; }
  #splash .pb-lg-4, #splash .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  #splash .pl-lg-4, #splash .px-lg-4 {
    padding-left: 1.5rem !important; }
  #splash .p-lg-5 {
    padding: 3rem !important; }
  #splash .pt-lg-5, #splash .py-lg-5 {
    padding-top: 3rem !important; }
  #splash .pr-lg-5, #splash .px-lg-5 {
    padding-right: 3rem !important; }
  #splash .pb-lg-5, #splash .py-lg-5 {
    padding-bottom: 3rem !important; }
  #splash .pl-lg-5, #splash .px-lg-5 {
    padding-left: 3rem !important; }
  #splash .m-lg-auto {
    margin: auto !important; }
  #splash .mt-lg-auto, #splash .my-lg-auto {
    margin-top: auto !important; }
  #splash .mr-lg-auto, #splash .mx-lg-auto {
    margin-right: auto !important; }
  #splash .mb-lg-auto, #splash .my-lg-auto {
    margin-bottom: auto !important; }
  #splash .ml-lg-auto, #splash .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  #splash .m-xl-0 {
    margin: 0 !important; }
  #splash .mt-xl-0, #splash .my-xl-0 {
    margin-top: 0 !important; }
  #splash .mr-xl-0, #splash .mx-xl-0 {
    margin-right: 0 !important; }
  #splash .mb-xl-0, #splash .my-xl-0 {
    margin-bottom: 0 !important; }
  #splash .ml-xl-0, #splash .mx-xl-0 {
    margin-left: 0 !important; }
  #splash .m-xl-1 {
    margin: 0.25rem !important; }
  #splash .mt-xl-1, #splash .my-xl-1 {
    margin-top: 0.25rem !important; }
  #splash .mr-xl-1, #splash .mx-xl-1 {
    margin-right: 0.25rem !important; }
  #splash .mb-xl-1, #splash .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  #splash .ml-xl-1, #splash .mx-xl-1 {
    margin-left: 0.25rem !important; }
  #splash .m-xl-2 {
    margin: 0.5rem !important; }
  #splash .mt-xl-2, #splash .my-xl-2 {
    margin-top: 0.5rem !important; }
  #splash .mr-xl-2, #splash .mx-xl-2 {
    margin-right: 0.5rem !important; }
  #splash .mb-xl-2, #splash .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  #splash .ml-xl-2, #splash .mx-xl-2 {
    margin-left: 0.5rem !important; }
  #splash .m-xl-3 {
    margin: 1rem !important; }
  #splash .mt-xl-3, #splash .my-xl-3 {
    margin-top: 1rem !important; }
  #splash .mr-xl-3, #splash .mx-xl-3 {
    margin-right: 1rem !important; }
  #splash .mb-xl-3, #splash .my-xl-3 {
    margin-bottom: 1rem !important; }
  #splash .ml-xl-3, #splash .mx-xl-3 {
    margin-left: 1rem !important; }
  #splash .m-xl-4 {
    margin: 1.5rem !important; }
  #splash .mt-xl-4, #splash .my-xl-4 {
    margin-top: 1.5rem !important; }
  #splash .mr-xl-4, #splash .mx-xl-4 {
    margin-right: 1.5rem !important; }
  #splash .mb-xl-4, #splash .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  #splash .ml-xl-4, #splash .mx-xl-4 {
    margin-left: 1.5rem !important; }
  #splash .m-xl-5 {
    margin: 3rem !important; }
  #splash .mt-xl-5, #splash .my-xl-5 {
    margin-top: 3rem !important; }
  #splash .mr-xl-5, #splash .mx-xl-5 {
    margin-right: 3rem !important; }
  #splash .mb-xl-5, #splash .my-xl-5 {
    margin-bottom: 3rem !important; }
  #splash .ml-xl-5, #splash .mx-xl-5 {
    margin-left: 3rem !important; }
  #splash .p-xl-0 {
    padding: 0 !important; }
  #splash .pt-xl-0, #splash .py-xl-0 {
    padding-top: 0 !important; }
  #splash .pr-xl-0, #splash .px-xl-0 {
    padding-right: 0 !important; }
  #splash .pb-xl-0, #splash .py-xl-0 {
    padding-bottom: 0 !important; }
  #splash .pl-xl-0, #splash .px-xl-0 {
    padding-left: 0 !important; }
  #splash .p-xl-1 {
    padding: 0.25rem !important; }
  #splash .pt-xl-1, #splash .py-xl-1 {
    padding-top: 0.25rem !important; }
  #splash .pr-xl-1, #splash .px-xl-1 {
    padding-right: 0.25rem !important; }
  #splash .pb-xl-1, #splash .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  #splash .pl-xl-1, #splash .px-xl-1 {
    padding-left: 0.25rem !important; }
  #splash .p-xl-2 {
    padding: 0.5rem !important; }
  #splash .pt-xl-2, #splash .py-xl-2 {
    padding-top: 0.5rem !important; }
  #splash .pr-xl-2, #splash .px-xl-2 {
    padding-right: 0.5rem !important; }
  #splash .pb-xl-2, #splash .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  #splash .pl-xl-2, #splash .px-xl-2 {
    padding-left: 0.5rem !important; }
  #splash .p-xl-3 {
    padding: 1rem !important; }
  #splash .pt-xl-3, #splash .py-xl-3 {
    padding-top: 1rem !important; }
  #splash .pr-xl-3, #splash .px-xl-3 {
    padding-right: 1rem !important; }
  #splash .pb-xl-3, #splash .py-xl-3 {
    padding-bottom: 1rem !important; }
  #splash .pl-xl-3, #splash .px-xl-3 {
    padding-left: 1rem !important; }
  #splash .p-xl-4 {
    padding: 1.5rem !important; }
  #splash .pt-xl-4, #splash .py-xl-4 {
    padding-top: 1.5rem !important; }
  #splash .pr-xl-4, #splash .px-xl-4 {
    padding-right: 1.5rem !important; }
  #splash .pb-xl-4, #splash .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  #splash .pl-xl-4, #splash .px-xl-4 {
    padding-left: 1.5rem !important; }
  #splash .p-xl-5 {
    padding: 3rem !important; }
  #splash .pt-xl-5, #splash .py-xl-5 {
    padding-top: 3rem !important; }
  #splash .pr-xl-5, #splash .px-xl-5 {
    padding-right: 3rem !important; }
  #splash .pb-xl-5, #splash .py-xl-5 {
    padding-bottom: 3rem !important; }
  #splash .pl-xl-5, #splash .px-xl-5 {
    padding-left: 3rem !important; }
  #splash .m-xl-auto {
    margin: auto !important; }
  #splash .mt-xl-auto, #splash .my-xl-auto {
    margin-top: auto !important; }
  #splash .mr-xl-auto, #splash .mx-xl-auto {
    margin-right: auto !important; }
  #splash .mb-xl-auto, #splash .my-xl-auto {
    margin-bottom: auto !important; }
  #splash .ml-xl-auto, #splash .mx-xl-auto {
    margin-left: auto !important; } }

#splash .text-justify {
  text-align: justify !important; }

#splash .text-nowrap {
  white-space: nowrap !important; }

#splash .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

#splash .text-left {
  text-align: left !important; }

#splash .text-right {
  text-align: right !important; }

#splash .text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  #splash .text-sm-left {
    text-align: left !important; }
  #splash .text-sm-right {
    text-align: right !important; }
  #splash .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  #splash .text-md-left {
    text-align: left !important; }
  #splash .text-md-right {
    text-align: right !important; }
  #splash .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  #splash .text-lg-left {
    text-align: left !important; }
  #splash .text-lg-right {
    text-align: right !important; }
  #splash .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  #splash .text-xl-left {
    text-align: left !important; }
  #splash .text-xl-right {
    text-align: right !important; }
  #splash .text-xl-center {
    text-align: center !important; } }

#splash .text-lowercase {
  text-transform: lowercase !important; }

#splash .text-uppercase {
  text-transform: uppercase !important; }

#splash .text-capitalize {
  text-transform: capitalize !important; }

#splash .font-weight-light {
  font-weight: 300 !important; }

#splash .font-weight-normal {
  font-weight: 400 !important; }

#splash .font-weight-bold {
  font-weight: 700 !important; }

#splash .font-italic {
  font-style: italic !important; }

#splash .text-white {
  color: #fff !important; }

#splash .text-primary {
  color: #007bff !important; }

#splash a.text-primary:focus, #splash a.text-primary:hover {
  color: #0062cc !important; }

#splash .text-secondary {
  color: #868e96 !important; }

#splash a.text-secondary:focus, #splash a.text-secondary:hover {
  color: #6c757d !important; }

#splash .text-success {
  color: #28a745 !important; }

#splash a.text-success:focus, #splash a.text-success:hover {
  color: #1e7e34 !important; }

#splash .text-info {
  color: #17a2b8 !important; }

#splash a.text-info:focus, #splash a.text-info:hover {
  color: #117a8b !important; }

#splash .text-warning {
  color: #ffc107 !important; }

#splash a.text-warning:focus, #splash a.text-warning:hover {
  color: #d39e00 !important; }

#splash .text-danger {
  color: #dc3545 !important; }

#splash a.text-danger:focus, #splash a.text-danger:hover {
  color: #bd2130 !important; }

#splash .text-light {
  color: #f8f9fa !important; }

#splash a.text-light:focus, #splash a.text-light:hover {
  color: #dae0e5 !important; }

#splash .text-dark {
  color: #343a40 !important; }

#splash a.text-dark:focus, #splash a.text-dark:hover {
  color: #1d2124 !important; }

#splash .text-muted {
  color: #868e96 !important; }

#splash .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

#splash .visible {
  visibility: visible !important; }

#splash .invisible {
  visibility: hidden !important; }

@media print {
  #splash *, #splash ::after, #splash ::before {
    text-shadow: none !important;
    box-shadow: none !important; }
  #splash a, #splash a:visited {
    text-decoration: underline; }
  #splash abbr[title]::after {
    content: " (" attr(title) ")"; }
  #splash pre {
    white-space: pre-wrap !important; }
  #splash blockquote, #splash pre {
    border: 1px solid #999;
    page-break-inside: avoid; }
  #splash thead {
    display: table-header-group; }
  #splash img, #splash tr {
    page-break-inside: avoid; }
  #splash h2, #splash h3, #splash p {
    orphans: 3;
    widows: 3; }
  #splash h2, #splash h3 {
    page-break-after: avoid; }
  #splash .navbar {
    display: none; }
  #splash .badge {
    border: 1px solid #000; }
  #splash .table {
    border-collapse: collapse !important; }
  #splash .table td, #splash .table th {
    background-color: #fff !important; }
  #splash .table-bordered td, #splash .table-bordered th {
    border: 1px solid #ddd !important; } }
